import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';

import type { TNavItem } from '../../types';
import type { WellPageNavStore } from '../../WellPageNav.store';
import type { WellIndexType } from '@go-widgets/well-logs-widget';

import { SortableItem } from '../sortable-item/SortableItem';

type SortableSectionProps = {
  id: string;
  items: TNavItem[];
  store: WellPageNavStore;
  onOpenedWellboreRun: VoidFunction;
  onChangeWellType(value: boolean): void;
  wellIndexType: WellIndexType;
};

export const SortableSection = observer(function SortableSection({
  id,
  items,
  store,
  onOpenedWellboreRun,
  onChangeWellType,
  wellIndexType,
}: SortableSectionProps) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
      <div ref={setNodeRef}>
        {items.map((item) => (
          <SortableItem
            key={item.action}
            id={item.id}
            navItem={item}
            store={store}
            onOpenedWellboreRun={onOpenedWellboreRun}
            onChangeWellType={onChangeWellType}
            wellIndexType={wellIndexType}
          />
        ))}
      </div>
    </SortableContext>
  );
});
