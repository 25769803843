import { SignInSqureLight as LogoutIcon } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import profileImg from 'src/packages/assets/img/profile.png';

import type { Auth } from 'src/app/auth/AuthStore';

import styles from './UserSection.module.scss';

type Props = {
  isOpened: boolean;
  onLogout: VoidFunction;
  userInfo: Auth.UserInfo | null;
};

export const UserSection = observer(function UserSection({ isOpened, userInfo, onLogout }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.photoWrapper}>
        <img className={styles.photo} src={profileImg} alt="avatar" />
      </div>
      <div className={clsx(styles.nameAndLogoutWrapper, isOpened && styles.nameAndLogoutWrapper__opened)}>
        <p className={clsx(styles.text)}>{userInfo?.name}</p>

        <button className={styles.logoutButton} onClick={onLogout}>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
});
