import { observer } from 'mobx-react-lite';

import type { IWellCollapseItemEntity } from './WellCollapseItem.entity';
import type { ReactNode, Ref } from 'react';

import styles from './../../WellCollapse.module.scss';

type Props<T extends IWellCollapseItemEntity> = {
  item: T;
  renderIcon?: (item: T) => ReactNode;
  renderButtons(): ReactNode;
  refAt(key: number): Ref<HTMLDivElement>;
  onClick: VoidFunction;
};

export const CollapseItem = observer(function CollapseItem<T extends IWellCollapseItemEntity>({
  item,
  renderIcon,
  renderButtons,
  refAt,
  onClick,
}: Props<T>) {
  return (
    <div className={styles.listItem} ref={refAt(item.id)} onClick={onClick}>
      <div className={styles.titleWrapper}>
        {renderIcon?.(item)}
        <p className={styles.title}>{item.name}</p>
      </div>
      <div className={styles.buttons} onClick={(e) => e.stopPropagation()}>
        {renderButtons()}
      </div>
    </div>
  );
});
