import { Capacitor } from '@capacitor/core';
import { MobileTabletWidget } from '@go-widgets/well-logs-widget';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { MainLayout } from 'src/layouts/main-layout/MainLayout';

import type { TUserMobilePreferences } from '../../api/UserMobilePreferences.api';
import type { NavList } from '../well-page-nav/types';
import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';

import styles from '../../WellPage.module.scss';
import { WellPageNav } from '../well-page-nav/WellPageNav';

import { WellLogsWidgetStore } from './WellLogsWidget.store';

type WellLogsWidgetProps = {
  wellId: number;
  wellIndexType: WellIndexType;
  navList: NavList;
  stateParams: Partial<WidgetStateParams>;
  onWellTypeChange: (value: boolean) => void;
  onPreferencesUpdate: (preferences: TUserMobilePreferences) => void;
};

const isAndroid = Capacitor.getPlatform() === 'android';
const ANDROID_MARGIN_BOTTOM = 34;

export const WellLogsWidget = observer(function WellLogsWidget({
  wellId,
  wellIndexType,
  navList,
  stateParams,
  onWellTypeChange,
  onPreferencesUpdate,
}: WellLogsWidgetProps) {
  const [store, setStore] = useState<WellLogsWidgetStore>();

  const state = store?.wellLogsEntity.state;
  const effect = store?.effect;

  useEffect(() => {
    if (!effect) return;
    const dispose = effect();

    return () => dispose();
  }, [effect]);

  useEffect(() => {
    const wellLogsWidgetStore = new WellLogsWidgetStore(wellId, wellIndexType, stateParams, onPreferencesUpdate);
    setStore(wellLogsWidgetStore);
  }, [onPreferencesUpdate, stateParams, wellId, wellIndexType]);

  if (!store) return null;

  return (
    <MainLayout>
      <div className={styles.container}>
        <div className={styles.content} style={{ ...(isAndroid && { marginBottom: ANDROID_MARGIN_BOTTOM }) }}>
          {state && <MobileTabletWidget widgetState={state} />}
        </div>
        <WellPageNav
          store={store}
          navList={navList}
          onWellTypeChange={onWellTypeChange}
          onPreferencesUpdate={onPreferencesUpdate}
        />
      </div>
    </MainLayout>
  );
});
