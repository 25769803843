import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { BottomSheet } from 'src/components/bottom-sheet/BottomSheet';
import { useService } from 'src/packages/di';

import type { NavList } from './types';
import type { TUserMobilePreferences } from '../../api/UserMobilePreferences.api';
import type { WellLogsWidgetStore } from '../well-logs-widget/WellLogsWidget.store';

import { SectionsNav } from '../sections-nav/SectionsNav';
import { SettingsSidebar } from '../settings-sidebar/SettingsSidebar';

import { FullNav } from './components/full-nav/FullNav';
import { ShortNav } from './components/short-nav/ShortNav';
import { WellboreRun } from './components/wellbore-run/WellboreRun';
import { WellPageNavStore } from './WellPageNav.store';

import styles from './WellPageNav.module.scss';

type WellPageNavProps = {
  store: WellLogsWidgetStore;
  navList: NavList;
  onWellTypeChange: (value: boolean) => void;
  onPreferencesUpdate: (preferences: TUserMobilePreferences) => void;
};

export const WellPageNav = observer(function WellPageNav({
  store: wellLogsWidgetStore,
  navList,
  onWellTypeChange,
  onPreferencesUpdate,
}: WellPageNavProps) {
  const { isPortraitOrientation } = useService('screen');
  const [store, setStore] = useState<WellPageNavStore>(
    () => new WellPageNavStore(wellLogsWidgetStore, navList, (navList: NavList) => onPreferencesUpdate({ navList }))
  );
  const {
    wellLogsEntity: { wellIndexType },
    wellLogsAdapter,
  } = wellLogsWidgetStore;

  useEffect(() => {
    const storeWellPageNav = new WellPageNavStore(wellLogsWidgetStore, navList, (navList: NavList) =>
      onPreferencesUpdate({ navList })
    );

    storeWellPageNav.init();
    setStore(storeWellPageNav);
  }, [wellLogsWidgetStore, navList, onPreferencesUpdate]);

  const [key, setKey] = useState<number>(0);
  const [isOpenedSheet, setOpenedSheet] = useState<boolean>(false);

  const [isSectionsOpen, setSectionsOpen] = useState<boolean>(false);
  const [isFullNavOpen, setFullNavOpen] = useState<boolean>(false);
  const [isWellboreRunOpen, setWellboreRunOpen] = useState<boolean>(false);
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const reset = (): void => {
    setSectionsOpen(false);
    setFullNavOpen(false);
    setWellboreRunOpen(false);
  };

  const handleOpenedSheet = (): void => {
    setKey((prevKey) => prevKey + 1);
    setFullNavOpen(true);
    setOpenedSheet(true);
  };

  const handleClosedSheet = (): void => {
    reset();
    setOpenedSheet(false);
  };

  const handleClosedFullNav = () => {
    setFullNavOpen(false);
    setOpenedSheet(false);
  };

  const handleOpenedSections = (): void => {
    setKey((prevKey) => prevKey + 1);
    setFullNavOpen(false);
    setSectionsOpen(true);
    setOpenedSheet(true);
  };

  const handleOpenedWellboreRun = (): void => {
    setKey((prevKey) => prevKey + 1);
    setFullNavOpen(false);
    setWellboreRunOpen(true);
    setOpenedSheet(true);
  };

  const handleClosedWellboreRun = (): void => {
    setWellboreRunOpen(false);
    setOpenedSheet(false);
  };

  const handleOpenedSettings = (): void => {
    setKey((prevKey) => prevKey + 1);
    reset();
    setSettingsOpen(true);
  };

  const handleCloseSettings = (): void => {
    setSettingsOpen(false);
  };

  return (
    <>
      <div className={styles.wellPageNav}>
        <BottomSheet
          key={key}
          isOpened={isOpenedSheet}
          onOpened={handleOpenedSheet}
          onClosed={handleClosedSheet}
          isPortraitOrientation={isPortraitOrientation}
        >
          {!isOpenedSheet && (
            <ShortNav
              store={store}
              onOpenSections={handleOpenedSections}
              navList={store.navList.visible}
              onOpenWellboreRun={handleOpenedWellboreRun}
              onChangeWellType={onWellTypeChange}
              wellIndexType={wellIndexType}
              onOpenSettings={handleOpenedSettings}
            />
          )}

          <SectionsNav onClose={handleClosedSheet} isOpened={isSectionsOpen} />
          <FullNav
            store={store}
            onClose={handleClosedFullNav}
            isOpened={isFullNavOpen}
            onOpenSections={handleOpenedSections}
            onOpenWellboreRun={handleOpenedWellboreRun}
            onChangeWellType={onWellTypeChange}
            wellIndexType={wellIndexType}
            onOpenSettings={handleOpenedSettings}
          />
          <WellboreRun store={wellLogsWidgetStore} isOpened={isWellboreRunOpen} onClose={handleClosedWellboreRun} />
        </BottomSheet>
      </div>

      <SettingsSidebar
        isOpened={isSettingsOpen}
        onClose={handleCloseSettings}
        adapter={wellLogsAdapter}
        store={wellLogsWidgetStore}
      />
    </>
  );
});
