import { Button } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DOMAIN_API_URL_KEY } from 'src/app/auth/consts';

import type { FormEvent, KeyboardEvent } from 'react';
import type { Auth } from 'src/app/auth/AuthStore';

import { isHttpsValid } from './DomainForm.utils';

import styles from './DomainForm.module.scss';

const MAX_HEIGHT_TEXTAREA = 64;

interface Props {
  userInfo: Auth.UserInfo | null;
  isErrorConfig: boolean;
  onDomainSubmit: (domain: string) => Promise<void>;
}

export const DomainForm = observer<Props>(function DomainForm({ userInfo, isErrorConfig, onDomainSubmit }) {
  const { t } = useTranslation('common');

  const domainApiUrl = localStorage.getItem(DOMAIN_API_URL_KEY);

  const [isDisabled, setIsDisabled] = useState(isHttpsValid(domainApiUrl));
  const [isUrl, setIsUrl] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleDomainChange = (event: FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value.trim();

    setIsDisabled(isHttpsValid(value));

    if (!textareaRef.current || textareaRef.current.scrollHeight > MAX_HEIGHT_TEXTAREA) return;

    textareaRef.current.value = value;
    textareaRef.current.style.height = 'inherit';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  const handleDomainKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  if (userInfo) return null;

  return (
    <>
      <p className={styles.authText}>{t('authText')}</p>
      <div className={styles.domainField}>
        <label className={styles.domainFieldLabel}>{t('domainName')}</label>
        <textarea
          className={styles.domainFieldTextarea}
          ref={textareaRef}
          onChange={handleDomainChange}
          onKeyDown={handleDomainKeyDown}
          rows={1}
          {...(domainApiUrl && { defaultValue: JSON.parse(domainApiUrl) })}
        ></textarea>
        {(isErrorConfig || isUrl) && <p className={styles.errorText}>{t('errorConfig')}</p>}
      </div>
      <Button
        onClick={() => {
          if (!textareaRef.current) return;

          try {
            const domain = new URL(textareaRef.current.value).origin;

            localStorage.setItem(DOMAIN_API_URL_KEY, JSON.stringify(domain));
            setIsUrl(false);
            setIsDisabled(false);
            onDomainSubmit(domain);
          } catch {
            setIsUrl(true);
          }
        }}
        className={styles.authButton}
        disabled={!isDisabled}
      >
        {t('next')}
      </Button>
    </>
  );
});
