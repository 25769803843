import { action, makeObservable } from 'mobx';

import { SidebarStore } from 'src/app/sidebar/SidebarStore';
import { requireService } from 'src/packages/di';
import { LanguageStore } from 'src/packages/language/LanguageStore';

export class RootStore {
  readonly sidebar;
  readonly language;

  constructor(readonly auth = requireService('authStore')) {
    this.sidebar = new SidebarStore();
    this.language = new LanguageStore();

    makeObservable(this);
  }

  @action.bound
  init() {
    this.language.init();
  }
}
