import { action, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';
import { ComputedDataSource } from 'src/packages/template-builder/TemplateBuilder';

import type { WellCollapseEntity } from './WellCollapse';
import type { ObservableSet } from 'mobx';
import type { TWellListView } from 'src/api-types/wells.types';
import type { IDataSource } from 'src/packages/template-builder/TemplateBuilder';

interface WellListWidgetOptions {
  filterBy: number | null;
  groupBy: number | null;
  searchValue: string | null;
  collapsedKeys: string[] | null;
}

export class WellListWidgetEntity {
  @observable wells: WellCollapseEntity[] = [];
  @observable view: TWellListView;
  @observable filterBy: number;
  @observable groupBy: number;
  @observable searchValue: string;
  @observable collapsedKeys: ObservableSet<string>;

  constructor(
    { filterBy, groupBy, searchValue, collapsedKeys }: WellListWidgetOptions,
    private readonly preloadService = requireService('preloadService')
  ) {
    const view = this.preloadService.getPreloadedData<TWellListView>('well-list-control');

    this.collapsedKeys = observable.set(collapsedKeys ?? []);
    this.groupBy = groupBy ?? view.grouping.defaultGroupingIndex;
    this.filterBy = filterBy ?? view.filtering.defaultFilterIndex;
    this.searchValue = searchValue ?? '';
    this.view = view;

    makeObservable(this);
  }

  getNameT(): string {
    return 'wellList:title';
  }

  @action.bound
  updateWellStatus(id: number, status: string): void {
    for (const wellGroup of this.wells) {
      for (const well of wellGroup.items) {
        if (well.id === id) {
          well.setStatus(status);

          return;
        }
      }
    }
  }

  @action.bound
  setCollapsedKeys(keys: string[] | undefined): void {
    this.collapsedKeys = observable.set(keys ?? []);
  }

  @action.bound
  addCollapsedKey(key: string): void {
    this.collapsedKeys.add(key);
  }

  @action.bound
  removeCollapsedKey(key: string): void {
    this.collapsedKeys.delete(key);
  }

  createDataSource(): IDataSource {
    return new ComputedDataSource(() => ({ search: this.searchValue }));
  }

  get isControlWidget(): boolean {
    return true;
  }

  get title(): string {
    const i18 = requireService('i18');

    return i18.t('wellList:title');
  }

  @action.bound
  changeFilterBy(filter: number): void {
    this.filterBy = filter;
  }

  @action.bound
  changeGroupBy(groupBy: number): void {
    this.groupBy = groupBy;
  }

  @action.bound
  changeSearchValue = (value: string) => {
    this.searchValue = value;
  };

  @action.bound
  setWells(wells: WellCollapseEntity[]): void {
    this.wells = wells;
  }
}
