import * as Sentry from '@sentry/react';
import axios from 'axios';
import { computed, makeObservable } from 'mobx';

import { getAccessToken } from 'src/app/auth/auth';
import { getLocale } from 'src/packages/language/utils';

import type { UserService } from 'src/app/auth/user-service';
import type { IServicesCollection } from 'src/packages/di';

import { type Globals } from './globals';

export class ApiService {
  constructor(private readonly globals: Globals, private readonly getUserService: () => UserService) {
    makeObservable(this);
  }

  get userService() {
    return this.getUserService();
  }

  @computed
  get userAgent() {
    const agent = axios.create({
      baseURL: this.globals.API_AUTH_URL,
      headers: {
        'content-type': 'application/json',
      },
    });

    agent.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          await this.userService.login();
        }

        Sentry.captureException(error);

        return Promise.reject(error);
      }
    );

    agent.interceptors.request.use((config) => {
      const accessToken = getAccessToken();

      if (accessToken) {
        config.headers = { ...config.headers };
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    return agent;
  }

  @computed
  get agent() {
    const agent = axios.create({
      baseURL: this.globals.API_URL,
      headers: {
        'content-type': 'application/json',
      },
    });

    agent.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          const accessToken = await this.userService.updateToken();

          if (error?.config && accessToken) {
            return axios.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }
        }

        Sentry.captureException(error);

        return Promise.reject(error);
      }
    );

    agent.interceptors.request.use((config) => {
      const accessToken = getAccessToken();

      config.headers = { ...config.headers };
      config.headers['accept-language'] = getLocale();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    return agent;
  }

  @computed
  get goStorageAgent() {
    const goStorageAgent = axios.create({
      baseURL: this.globals.API_GO_STORAGE_URL,
      headers: {
        'content-type': 'application/json',
      },
    });

    goStorageAgent.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          const accessToken = await this.userService.updateToken();

          if (error?.config && accessToken) {
            return axios.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }
        }

        Sentry.captureException(error);

        return Promise.reject(error);
      }
    );

    goStorageAgent.interceptors.request.use((config) => {
      const accessToken = getAccessToken();

      config.headers = { ...config.headers };
      config.headers['accept-language'] ??= getLocale();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    return goStorageAgent;
  }

  @computed
  get legacyAgent() {
    const legacyAgent = axios.create({
      baseURL: this.globals.API_LEGACY_CACHE_URL,
      headers: {
        'content-type': 'application/json',
      },
    });

    legacyAgent.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          if (error?.config) {
            return axios.request({
              ...error.config,
              headers: {
                ...error.config.headers,
              },
            });
          }
        }

        Sentry.captureException(error);

        return Promise.reject(error);
      }
    );

    legacyAgent.interceptors.request.use((config) => {
      config.headers = { ...config.headers };
      config.headers['accept-language'] = getLocale();

      return config;
    });

    return legacyAgent;
  }

  @computed
  get wellLogsWidgetAgent() {
    const wellLogsWidgetAgent = axios.create({
      baseURL: this.globals.API_GO_STORAGE_WELL_LOGS_URL,
      headers: {
        'content-type': 'application/json',
      },
    });

    wellLogsWidgetAgent.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          const accessToken = await this.userService.updateToken();

          if (error?.config && accessToken) {
            return axios.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }
        }

        Sentry.captureException(error);

        return Promise.reject(error);
      }
    );

    wellLogsWidgetAgent.interceptors.request.use((config) => {
      const accessToken = getAccessToken();

      config.headers = { ...config.headers };
      config.headers['accept-language'] = getLocale();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    return wellLogsWidgetAgent;
  }

  @computed
  get wellDirectoryServiceAgent() {
    const wellDirectoryServiceAgent = axios.create({
      baseURL: this.globals.WELL_DIRECTORY_SERVICE_API_URL,
      headers: {
        'content-type': 'application/json',
      },
    });

    wellDirectoryServiceAgent.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          const accessToken = await this.userService.updateToken();

          if (error?.config && accessToken) {
            return axios.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }
        }

        Sentry.captureException(error);

        return Promise.reject(error);
      }
    );

    wellDirectoryServiceAgent.interceptors.request.use((config) => {
      const accessToken = getAccessToken();

      config.headers = { ...config.headers };
      config.headers['accept-language'] = getLocale();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    return wellDirectoryServiceAgent;
  }
}

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    apiService: ApiService;
  }
}

export function addApiService(di: IServicesCollection) {
  const globals = di.requireService('globals');
  const getUserService = di.requireServiceAccessor('userService');

  di.addSingleton('apiService', new ApiService(globals, getUserService));
}
