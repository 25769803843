export function hasValue<T>(value: T | null | undefined): value is T {
  if (Array.isArray(value)) {
    return value.length !== 0;
  }

  return value !== null && value !== undefined && value !== 'null';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}
