import {
  MenuAltLight as OpenSidebarIcon,
  ExpandLeftLight as SearchCloseIcon,
  SearchLight as SearchIcon,
} from '@profgeosoft-ui/icons';
import { Input } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'src/app/root-store';

import type { WellListWidgetStore } from '../../WellListWidget.store';

import styles from './WellPageHeader.module.scss';
import { useSafeAreaView } from 'src/packages/shared/hooks/use-safe-area-view';
import { useService } from 'src/packages/di';

type WellPageHeaderProps = {
  store: WellListWidgetStore;
};

export const WellPageHeader = observer(function WellPageHeader({ store }: WellPageHeaderProps) {
  const { t } = useTranslation();
  const { sidebar } = useStore();
  const { changeSearchValue, wellListEntity } = store;
  const { searchValue } = wellListEntity;

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);

  const { isPortraitOrientation } = useService('screen');
  const { right } = useSafeAreaView();

  const handleOpenSearch = (): void => {
    setIsOpenSearch((prevIsOpen) => !prevIsOpen);

    if (!isOpenSearch) {
      inputRef.current?.focus();
    }
  };

  const style = !isPortraitOrientation && right ? {marginRight: 16} : undefined;

  return (
    <div className={styles.header} style={style}>
      <div className={clsx(styles.titleWrapper, isOpenSearch && styles.titleWrapper__opened)}>
        <button className={styles.openButton} onClick={sidebar.open}>
          <OpenSidebarIcon />
        </button>
        <h3 className={styles.pageTitle}>{t('pages:wells')}</h3>
      </div>
      <div className={clsx(styles.searchWrapper, isOpenSearch && styles.searchWrapper__opened)}>
        <button className={styles.openSearch} onClick={handleOpenSearch}>
          {isOpenSearch ? <SearchCloseIcon /> : <SearchIcon />}
        </button>
        <Input
          allowClear
          fieldClassName={styles.search}
          prefix={<SearchIcon className={styles.searchIcon} />}
          value={searchValue}
          inputClassName={styles.searchInput}
          placeholder={t('wellList:search')}
          onChange={(e) => changeSearchValue(e.target.value)}
          ref={inputRef}
        />
      </div>
    </div>
  );
});
