import { observer } from 'mobx-react-lite';
import { useState, type FC, useEffect } from 'react';

import { MainLayout } from 'src/layouts/main-layout/MainLayout';
import { useRefs } from 'src/packages/shared/hooks/useRefsArray';

import { WellListWidget } from './features/well-list-widget/WellListWidget';
import { WellPageHeader } from './features/well-page-header/WellPageHeader';
import { WellListWidgetStore } from './WellListWidget.store';

export const WellsPage: FC = observer(function WellsPage() {
  const [store, setStore] = useState<WellListWidgetStore | null>(null);
  const { valueAt, clearRefs, refAt } = useRefs<HTMLDivElement, number>();

  useEffect(() => {
    const newStore = new WellListWidgetStore(valueAt, clearRefs);

    setStore(newStore);
  }, [setStore, clearRefs, valueAt]);

  if (!store) return null;

  return (
    <MainLayout header={<WellPageHeader store={store} />}>
      <WellListWidget store={store} refAt={refAt} />
    </MainLayout>
  );
});
