import { requireService, type IServicesCollection } from 'src/packages/di';

import type { ISockClientService } from './SockClientService';

import { SockClientService } from './SockClientService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    wellStatusService: ISockClientService;
  }
}

export function addSockClientService(di: IServicesCollection): void {
  const wellStatusService = new SockClientService();
  const globals = requireService('globals');
  wellStatusService.init(globals);

  di.addSingleton('wellStatusService', wellStatusService);
}
