import { DrillingRigCustom as DrillingIcon } from '@profgeosoft-ui/icons';

export const navProps = [
  {
    name: 'Nav.wells',
    to: '/wells',
    renderIcon: (className: string) => <DrillingIcon className={className} />,
  },
  // {
  //   name: 'Nav.analytics',
  //   to: '/analytics',
  //   renderIcon: (className: string) => <ChartIcon className={className} />,
  // },
];
