import { arrayMove } from '@dnd-kit/sortable';
import { action, makeObservable, observable } from 'mobx';

import type { NavItemAction, NavList } from './types';
import type { WellLogsWidgetStore } from '../well-logs-widget/WellLogsWidget.store';
import type { DragEndEvent, DragOverEvent, UniqueIdentifier } from '@dnd-kit/core';
import type { WellLogWidgetObservableAdapter } from '@go-widgets/well-logs-widget';

export class WellPageNavStore {
  wellLogsAdapter: WellLogWidgetObservableAdapter;
  setNavList: (navList: NavList) => void;

  @observable navList: NavList = {};

  constructor(store: WellLogsWidgetStore, navList: NavList, setNavList: (navList: NavList) => void) {
    this.wellLogsAdapter = store.wellLogsAdapter;
    this.navList = navList;
    this.setNavList = setNavList;

    makeObservable(this);
  }

  private findSectionContainer(navList: NavList, id: UniqueIdentifier) {
    if (id in navList) {
      return id;
    }

    const container = Object.keys(navList).find((key) => navList[key].find((item) => item.id === id));

    return container;
  }

  private setValuesOnNavList(action: NavItemAction, value: boolean | number | null): void {
    for (const key of Object.keys(this.navList)) {
      const itemNav = this.navList[key];
      for (const i in itemNav) {
        if (itemNav[i].action === action) {
          itemNav[i].value = value;
          break;
        }
      }
    }

    this.setNavList(this.navList);
  }

  @action.bound
  onNavButtonClick(action: NavItemAction): void {
    switch (action) {
      case 'target':
        const infoPanels = !this.wellLogsAdapter.infoPanels;
        this.wellLogsAdapter.setInfoPanels(infoPanels);
        this.setValuesOnNavList(action, infoPanels);
        break;
      case 'header':
        const trackInfoVisible = !this.wellLogsAdapter.trackInfoVisible;
        this.wellLogsAdapter.setTrackInfoVisible(trackInfoVisible);
        this.setValuesOnNavList(action, trackInfoVisible);
        break;
      case 'autoScroll':
        const autoScroll = !this.wellLogsAdapter.autoScroll;
        this.wellLogsAdapter.setAutoScroll(autoScroll);
        this.setValuesOnNavList(action, autoScroll);
        break;
    }
  }

  @action.bound
  onNavSwitchClick(action: NavItemAction, value: boolean): void {
    switch (action) {
      case 'depthTime':
        this.setValuesOnNavList(action, value);
        break;
    }
  }

  @action.bound
  onNavDateChange(action: NavItemAction, value: number): void {
    switch (action) {
      case 'search':
        this.wellLogsAdapter.setScaleOffsetDate(value);
        break;
    }
  }

  @action.bound
  onDragOver({ active, over }: DragOverEvent): void {
    if (!over) return;

    const activeContainer = this.findSectionContainer(this.navList, active.id);
    const overContainer = this.findSectionContainer(this.navList, over?.id);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    const activeItems = this.navList[activeContainer];
    const overItems = this.navList[overContainer];

    const activeIndex = activeItems.findIndex((item) => item.id === active.id);
    const overIndex = overItems.findIndex((item) => item.id !== over?.id);

    this.navList = {
      ...this.navList,
      [activeContainer]: [...this.navList[activeContainer].filter((item) => item.id !== active.id)],
      [overContainer]: [
        ...this.navList[overContainer].slice(0, overIndex),
        this.navList[activeContainer][activeIndex],
        ...this.navList[overContainer].slice(overIndex, this.navList[overContainer].length),
      ],
    };
  }

  @action.bound
  onDragEnd({ active, over }: DragEndEvent): void {
    if (!over) return;

    const activeContainer = this.findSectionContainer(this.navList, active.id);
    const overContainer = this.findSectionContainer(this.navList, over?.id);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = this.navList[activeContainer].findIndex((task) => task.id === active.id);
    const overIndex = this.navList[overContainer].findIndex((task) => task.id === over?.id);

    if (activeIndex !== overIndex) {
      const navList = {
        ...this.navList,
        [overContainer]: arrayMove(this.navList[overContainer], activeIndex, overIndex),
      };

      this.setNavList(navList);

      //TODO: дописать алгоритм перемещения

      // const _navListVisible = [...this.navList.visible];
      // const _navListHidden = [...this.navList.hidden];

      // const weightVisible = _navListVisible.reduce((acc, item) => (acc += item.widthElement), 0);

      // const lastElement = _navListVisible.at(-1);

      // if (weightVisible > 5 && lastElement) {
      //   if (lastElement?.widthElement === 2) {
      //     const prevElement = _navListVisible.at(-2);
      //     if (prevElement) {
      //       _navListHidden.splice(0, 0, prevElement);
      //       _navListVisible.splice(-2, 1);
      //     }
      //   } else {
      //     _navListVisible.pop();
      //     _navListHidden.splice(0, 0, lastElement);
      //   }
      //   console.log('_navListVisible', _navListVisible);
      //   console.log('_navListHidden', _navListHidden);

      //   this.navList = {
      //     visible: _navListVisible,
      //     hidden: _navListHidden,
      //   };
      // }
    }
  }

  init = () => {
    for (const key of Object.keys(this.navList)) {
      this.navList[key].forEach((item, index) => {
        const value = this.navList[key][index].value;

        if (typeof value !== 'boolean') return;

        switch (item.action) {
          case 'target':
            this.wellLogsAdapter.setInfoPanels(value);
            break;
          case 'header':
            this.wellLogsAdapter.setTrackInfoVisible(value);
            break;
          case 'autoScroll':
            this.wellLogsAdapter.setAutoScroll(value);
            break;
        }
      });
    }
  };
}
