import { addApiService } from 'src/api/ApiService';
import { addGlobalsService } from 'src/api/globals';
import { addClassesOfUnitsService } from 'src/packages/class-of-units-service/addClassesOfUntisService';
import { di } from 'src/packages/di';
import { addDirectoriesService } from 'src/packages/directories/addDirectoriesService';
import { addDirectoriesApiService } from 'src/packages/directories/api/directories.api';
import { addFavoritesWellsService } from 'src/packages/favorites-wells-service/addFavoritesService';
import { addLocalStorageService } from 'src/packages/local-storage-service/addLocalStorageService';
import { addWellLogsTemplateService } from 'src/packages/well-logs-template-service/addWellLogsTemplateService';
import { addSockClientService } from 'src/packages/well-status-service/addSockClientService';

import type { KeycloakConfigResponse } from 'src/api/globals';

import { addLanguageService } from '../../packages/language/addLanguageService';
import { addScreenOrientationService } from '../../packages/screen-orientation-service/addScreenOrientationService';
import { addAuthStore, type Auth } from '../auth/AuthStore';
import { addUserService } from '../auth/user-service/UserService';
import { addI18Service } from '../i18n/addI18Service';
import { addNotificationsStore } from '../notifications-store/addNotificationsStore';

import { addCommonServices } from './commonServices';

export async function initAppServicesBeforeAuth(domainUrl: string, keycloakConfig: KeycloakConfigResponse) {
  return Promise.all([
    addGlobalsService(di, domainUrl, keycloakConfig),
    addApiService(di),
    addUserService(di),
    addNotificationsStore(di),
    addDirectoriesApiService(di),
    addLocalStorageService(di),
    addLanguageService(di),
    addScreenOrientationService(di),
  ]);
}

export async function initAppServicesAfterAuth(userInfo: Auth.UserInfo) {
  return Promise.all([
    addAuthStore(di, userInfo),
    addCommonServices(di),
    addFavoritesWellsService(di),
    addSockClientService(di),
    addWellLogsTemplateService(di),
    addDirectoriesService(di),
    addI18Service(di),
    addClassesOfUnitsService(di),
  ]);
}
