import { computed, makeObservable } from 'mobx';

import type { IServicesCollection } from 'src/packages/di';

export interface KeycloakConfigResponse {
  keycloakUrl: string;
  keycloakRealmName: string;
  keycloakClientId: string;
}

export class Globals {
  constructor(private readonly domainUrl: string, private readonly keycloakConfig: KeycloakConfigResponse) {
    makeObservable(this);
  }

  @computed
  get API_AUTH_URL() {
    return this.keycloakConfig.keycloakUrl;
  }

  @computed
  get KEYCLOAK_CLIENT_ID() {
    return this.keycloakConfig.keycloakClientId;
  }

  @computed
  get KEYCLOAK_REALM_NAME() {
    return this.keycloakConfig.keycloakRealmName;
  }

  @computed
  get API_URL() {
    return `${this.domainUrl}/well-directory-service/api/v2`;
  }

  @computed
  get API_GO_STORAGE_URL() {
    return `${this.domainUrl}/service/v1/`;
  }

  @computed
  get API_LEGACY_CACHE_URL() {
    return `${this.domainUrl}/cache/api/v1/`;
  }

  @computed
  get API_GO_STORAGE_WELL_LOGS_URL() {
    return `${this.domainUrl}/`;
  }

  @computed
  get API_WEBSOCKET_URL() {
    return `${this.domainUrl.replace('https:', 'wss:')}/cache/api/ws`;
  }

  @computed
  get API_WELL_STATUS_SOCKET_URL() {
    return `${this.domainUrl}/well-directory-service/ws/`;
  }

  @computed
  get WELL_DIRECTORY_SERVICE_API_URL() {
    return `${this.domainUrl}/well-directory-service/api/v2/`;
  }
}

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    globals: Globals;
  }
}

export function addGlobalsService(di: IServicesCollection, domain: string, keycloakConfig: KeycloakConfigResponse) {
  di.addSingleton('globals', new Globals(domain, keycloakConfig));
}
