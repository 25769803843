import type { ClassOfUnit, IClassOfUnitsService } from '@go-widgets/well-logs-widget';

import { requireService } from '../di';

export class ClassOfUnitsService implements IClassOfUnitsService {
  units: ClassOfUnit[] = [];
  constructor(
    private readonly notifications = requireService('notifications'),
    private readonly apiService = requireService('apiService')
  ) {}

  async init() {
    try {
      const response = await this.apiService.wellDirectoryServiceAgent.get<ClassOfUnit[]>('/class-of-unit');
      this.units = response.data;
    } catch (error) {
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadUnits');
    }
  }

  findClassById = (id: number) => {
    const unitClass = this.units.find((unit) => unit.id === id);
    return unitClass ?? false;
  };
}
