import { Capacitor } from '@capacitor/core';
import {
  UsersAltLight as RigCrewIcon,
  DrillingRigCustom as RigIcon,
  StarFill as StarIcon,
  StarLight as StarOutlinedIcon,
  Status as StatusIcon,
  WellClusterLightCustom as WellClusterIcon,
} from '@profgeosoft-ui/icons';
import { Button, Loader, RadioButtonGroup } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useService, useServiceAccessor } from 'src/packages/di';
import { useSafeAreaView } from 'src/packages/shared/hooks/use-safe-area-view';

import type { WellItemEntity } from './WellCollapse';
import type { WellListWidgetStore } from '../../WellListWidget.store';
import type { IWellCollapseItemEntity } from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { MouseEvent, Ref } from 'react';
import type { TWellListView } from 'src/api-types/wells.types';

import { WellCollapse } from '../well-collapse/WellCollapse';

import styles from './WellListWidget.module.scss';

type WellListProps = {
  store: WellListWidgetStore;
  refAt: <U extends HTMLDivElement>(key: number) => Ref<U>;
};

const groupingIcons: Partial<Record<string, React.ReactElement>> = {
  'rig-icon': <RigIcon className={styles.groupTypeIcon} />,
  'rig-crew-icon': <RigCrewIcon className={styles.groupTypeIcon} />,
  'status-icon': <StatusIcon className={styles.groupTypeIcon} />,
  'location-icon': <WellClusterIcon className={styles.groupTypeIcon} />,
};

const isIOS = Capacitor.getPlatform() === 'ios';
const isAndroid = Capacitor.getPlatform() === 'android';
const WEB_PADDING_BOTTOM = 8;
const WEB_PADDING_RIGHT = 8;
const ANDROID_PADDING_BOTTOM = 34;
const ANDROID_PADDING_RIGHT = 24;

export const WellListWidget = observer(function WellListWidget({ store, refAt }: WellListProps) {
  const { theme } = useService('theme');
  const { isPortraitOrientation } = useService('screen');
  const {
    changeFilterBy,
    changeGroupBy,
    isLoading,
    toggleFavorite,
    favoritesWells,
    onCollapseKeysChange,
    activeKey,
    fetchWells,
    isInitiated,
    effect,
    wellListEntity,
  } = store;

  const {
    filterBy,
    groupBy,
    wells,
    view: { item: itemView },
  } = wellListEntity;

  const { right, bottom, left } = useSafeAreaView();

  const paddingBottom = isIOS ? bottom + WEB_PADDING_BOTTOM : isAndroid ? ANDROID_PADDING_BOTTOM : WEB_PADDING_BOTTOM;
  const paddingRight = isIOS ? right : isAndroid ? ANDROID_PADDING_RIGHT : WEB_PADDING_RIGHT;

  useEffect(() => {
    if (!isInitiated) {
      fetchWells();
    }
  }, [isInitiated, fetchWells]);

  useEffect(effect, [effect]);

  const getDataService = useServiceAccessor('preloadService');
  const { t } = useTranslation('wellLogs');

  const dataService = getDataService();
  const wellListView = dataService.getPreloadedData<TWellListView>('well-list-control');

  const filteringItems = wellListView.filtering.filters.map((item, index) => ({
    label: t(`Labels:${item.name}.label`, { defaultValue: item.name }),
    value: index,
  }));

  const groupingItems = wellListView.grouping.groups.map((group, index) => {
    const { icon } = group;

    return {
      label: groupingIcons[icon] ?? group.name,
      value: index,
    };
  });

  const _onFavoriteToggle = (e: MouseEvent, item: IWellCollapseItemEntity) => {
    e.stopPropagation();
    toggleFavorite(item);
  };

  const renderButtons = (item: IWellCollapseItemEntity) => (
    <>
      <Button
        icon={
          favoritesWells.hasWell(item) ? (
            <StarIcon className={styles.activeIcon} />
          ) : (
            <StarOutlinedIcon className={styles.icon} />
          )
        }
        variant="flat"
        onClick={(e) => _onFavoriteToggle(e, item)}
        className={styles.iconButton}
      />
    </>
  );

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader className={styles.loader} />}
      <div className={styles.horizontalContent}>
        <RadioButtonGroup
          variant="flat"
          items={filteringItems}
          value={filterBy}
          className={styles.switchContainer}
          itemClassName={styles.switchButton}
          onChange={changeFilterBy}
          style={{ paddingLeft: left }}
        />
        <div className={styles.contentWrapper}>
          <div className={styles.scrollWellCollapse}>
            <div style={{ paddingLeft: !isPortraitOrientation ? left : 0 }}>
              <WellCollapse<WellItemEntity>
                renderIcon={(item) => {
                  const color =
                    item.status === 'COMPLETED' && theme === 'light' ? '#788CB9' : itemView.statusColor[item.status];
                  return <RigIcon className={styles.drillingIcon} style={{ color: color ?? '#ffff80' }} />;
                }}
                renderButtons={renderButtons}
                collapses={wells}
                refAt={refAt}
                activeKey={activeKey}
                onChange={onCollapseKeysChange}
              />
            </div>
          </div>
        </div>
      </div>
      <RadioButtonGroup
        variant="flat"
        items={groupingItems}
        value={groupBy}
        style={!isPortraitOrientation ? { paddingRight } : { paddingBottom }}
        itemClassName={styles.groupTypeButton}
        className={styles.groupTypeContainer}
        onChange={changeGroupBy}
      />
    </div>
  );
});
