import { DashboardService, type WellIndexType } from '@go-widgets/well-logs-widget';

import { requireService } from 'src/packages/di';

import { WellLogsTemplatesManager } from './WellLogsTemplatesManager';

export interface IWellLogsTemplateService {
  readonly api: DashboardService;
  registerTemplateManager(type: WellIndexType): void;
  getTemplatesManager(type: WellIndexType): WellLogsTemplatesManager;
}

export class WellLogsTemplateService implements IWellLogsTemplateService {
  readonly api: DashboardService;

  private templatesManagerMap: Map<WellIndexType, WellLogsTemplatesManager> = new Map();

  constructor(private readonly apiService = requireService('apiService')) {
    this.api = new DashboardService(this.apiService.wellLogsWidgetAgent);
  }

  getTemplatesManager(type: WellIndexType): WellLogsTemplatesManager {
    const templatesManager = this.templatesManagerMap.get(type);

    if (!templatesManager) {
      throw new Error(`${type} - templates manager not found`);
    }

    return templatesManager;
  }

  async registerTemplateManager(type: WellIndexType): Promise<void> {
    if (this.templatesManagerMap.has(type)) {
      throw new Error('template manager already registered');
    }

    const manager = new WellLogsTemplatesManager(type, this.api, {});

    await manager.init();

    this.templatesManagerMap.set(type, manager);
  }

  init = async () => {
    try {
      let promises: Promise<void>[] = [];

      this.templatesManagerMap.forEach((manager) => {
        promises.push(manager.init());
      });
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };
}
