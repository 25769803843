import { WellLogWidgetStompPublisher } from '@go-widgets/well-logs-widget';

import type { Globals } from 'src/api/globals';

import { getWebsocketAddress } from '../Workspace.utils';

export interface IStompPublisherService {
  stompPublisher: WellLogWidgetStompPublisher;
}

export class StompPublisherService implements IStompPublisherService {
  constructor(private readonly globals: Globals) {}
  stompPublisher = new WellLogWidgetStompPublisher();

  init(): void {
    if ((this.globals.API_WEBSOCKET_URL || '').startsWith('wss://')) {
      this.stompPublisher.init(this.globals.API_WEBSOCKET_URL, '');
    } else {
      this.stompPublisher.init(getWebsocketAddress(this.globals.API_WEBSOCKET_URL), '');
    }
  }
}
