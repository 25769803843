import { ConfigProvider } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import { useService } from 'src/packages/di';
import { AnalyticsPage } from 'src/pages/analytics/AnalyticsPage';
import { WellPage } from 'src/pages/well/WellPage';
import { WellsPage } from 'src/pages/wells/WellsPage';

import type { FC } from 'react';

import { getLocale } from './utils';

export const Router: FC = observer(function Router() {
  const theme = useService('theme');
  const language = useService('language');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme.theme);
  }, [theme.theme]);

  return (
    <ConfigProvider
      config={{
        theme: theme.theme,
        style: 'bordered',
        localization: getLocale(language.language),
        size: 's',
      }}
      externalElement={document.body}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/wells" element={<WellsPage />} />
          <Route path="/wells/:wellId" element={<WellPage />} />
          <Route path="/analytics" element={<AnalyticsPage />} />
          <Route path="*" element={<Navigate to="/wells" />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
});

export default Router;
