import { BackLight as BackIcon } from '@profgeosoft-ui/icons';
import { RadioButtonGroup } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkIsNode } from 'src/packages/shared/utils/checkIsNode';

import type { WellLogsWidgetStore } from '../well-logs-widget/WellLogsWidget.store';
import type { WellLogWidgetObservableAdapter } from '@go-widgets/well-logs-widget';

import { CommonTab } from './components/common-tab/CommonTab';
import { ConfigTab } from './components/config-tab/ConfigTab';
import { TemplatesTab } from './components/templates-tab/TemplatesTab';
import { getSettingsTabs } from './utils';

import styles from './SettingsSidebar.module.scss';

type SettingsSidebarProps = {
  isOpened: boolean;
  onClose: VoidFunction;
  adapter: WellLogWidgetObservableAdapter;
  store: WellLogsWidgetStore;
};

export const SettingsSidebar = observer(function SettingsSidebar({
  isOpened,
  onClose,
  adapter,
  store,
}: SettingsSidebarProps) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [tabIndex, setTabIndex] = useState<string | number>(1);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (ref.current!.contains(event.target)) return;
      onClose();
      setTabIndex(1);
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.body.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={ref} className={clsx(styles.wrapper, !isOpened && styles.wrapper__closed)}>
      <div className={styles.title}>
        <button className={styles.backButton} onClick={onClose}>
          <BackIcon />
        </button>
        <h3 className={styles.titleText}>{t('settings:title')}</h3>
      </div>
      <RadioButtonGroup
        variant="flat"
        items={getSettingsTabs()}
        value={tabIndex}
        className={styles.switchContainer}
        onChange={setTabIndex}
      />
      <div className={styles.settingsWrapper}>
        <CommonTab isOpen={tabIndex === 1} adapter={adapter} />
        {adapter.isTabletReady && <ConfigTab isOpen={tabIndex === 2} store={store} isOpenedSettings={isOpened} />}
        <TemplatesTab isOpen={tabIndex === 3} adapter={adapter} />
      </div>
    </div>
  );
});
