import type { TWellListView } from 'src/api-types/wells.types';

import { WorkspaceApi } from '../api/TabsApi';

export class PreloadWidgetService {
  private readonly api = new WorkspaceApi();

  private collection: Map<string, object> & {
    get<T>(type: string): T;
    set<T>(type: string, data: T): void;
  } = new Map();

  async preload(): Promise<void> {
    try {
      const [wellListView] = await Promise.all([this.api.fetchWellListView()]);

      this.collection.set<TWellListView>('well-list-control', wellListView);
    } catch (error) {
      console.error(error);
    }
  }

  getPreloadedData<T>(type: string): T {
    if (!this.collection.has(type)) {
      console.error(`${type} - data not found`);
      throw new Error('data not found');
    }

    return this.collection.get<T>(type);
  }
}
