import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

import type { CompatClient, StompSubscription } from '@stomp/stompjs';
import type { Globals } from 'src/api/globals';

export interface ISockClientService {
  init(globals: Globals): void;
  subscribe(key: string, callback: TStompCallback): void;
  unsubscribe(key: string, callback: TStompCallback): void;
}

export type TWellStatus = 'COMPLETED' | 'ACTIVE_GREEN' | 'ACTIVE_YELLOW' | 'ACTIVE_RED';

export type WellStatusMessage = {
  wellId: number;
  status: TWellStatus;
};

type TSocketMessage = WellStatusMessage;

type TStompCallback = (value: TSocketMessage) => void;

export class SockClientService implements ISockClientService {
  private socket: WebSocket | null = null;
  private client: CompatClient | null = null;
  private subscribers: Map<string, { callbackList: TStompCallback[]; subscription?: StompSubscription }> = new Map();

  subscribe(key: string, callback: TStompCallback): void {
    if (!this.subscribers.has(key)) {
      this.subscribers.set(key, { callbackList: [] });
    }

    const subscriberData = this.subscribers.get(key);

    if (this.client?.connected && subscriberData) {
      const { callbackList } = subscriberData;
      callbackList.push(callback);

      const subscription = this.client?.subscribe(key, (message) => {
        const data = JSON.parse(message.body ?? '');
        callbackList.forEach((cb) => cb(data));
      });

      subscriberData.subscription = subscription;
    }
  }

  init(globals: Globals): void {
    this.socket = new SockJS(globals.API_WELL_STATUS_SOCKET_URL);

    this.client = Stomp.over(this.socket);

    this.client?.connect({}, () => {
      for (const [key, subscriberData] of this.subscribers) {
        const subscription = this.client?.subscribe(key, (message) => {
          const data = JSON.parse(message.body ?? '');
          subscriberData.callbackList.forEach((callback) => callback(data));
        });

        subscriberData.subscription = subscription;
      }
    });
  }

  unsubscribe(key: string, callback: TStompCallback): void {
    const subscriberData = this.subscribers.get(key);

    if (!subscriberData) return;

    const { callbackList } = subscriberData;
    const index = callbackList.findIndex((cb) => cb === callback);

    if (index === -1) return;

    callbackList.splice(index, 1);

    if (this.client?.connected && callbackList.length === 0 && subscriberData.subscription) {
      subscriberData.subscription.unsubscribe();
      this.subscribers.delete(key);
    }
  }
}
