import { useRef } from 'react';

type TServiceNames = keyof ServicesCollectionMap;
type InferServiceType<TName extends TServiceNames> = ServicesCollectionMap[TName];
type TServiceFactoryFn<TName extends TServiceNames> = () => InferServiceType<TName>;

type TServicesMap = Map<string, object> & {
  get<TName extends TServiceNames>(name: TName): InferServiceType<TName> | undefined;
  set<TName extends TServiceNames>(name: TName, service: InferServiceType<TName>): TServicesMap;
};
type TServiceFactoriesMap = Map<string, Function> & {
  get<TName extends TServiceNames>(name: TName): TServiceFactoryFn<TName> | undefined;
  set<TName extends TServiceNames>(name: TName, instantiateService: TServiceFactoryFn<TName>): TServicesMap;
};

class ServicesCollection implements IServicesCollection, IServicesProvider {
  protected readonly services: TServicesMap = new Map();
  protected readonly serviceFactories: TServiceFactoriesMap = new Map();

  requireService<TName extends TServiceNames>(name: TName): InferServiceType<TName> {
    const service = this.services.get<TName>(name);
    if (!service) {
      const serviceFactory = this.serviceFactories.get(name);
      if (!serviceFactory) throw new Error(`Service '${name}' was not registered!`);

      const service = serviceFactory();
      this.services.set(name, service);
      return service;
    }

    return service;
  }

  requireServiceAccessor<TName extends TServiceNames>(name: TName): () => InferServiceType<TName> {
    let service: InferServiceType<TName> | undefined;

    return () => {
      if (!service) {
        service = this.requireService(name);
      }

      return service;
    };
  }

  addSingleton<TName extends TServiceNames>(name: TName, service: InferServiceType<TName>): this {
    // if (this.services.has(name) || this.serviceFactories.has('name')) throw new Error(`Service ${name} was registered`);

    this.services.set<TName>(name, service);
    return this;
  }

  addSingletonFactory<TName extends TServiceNames>(name: TName, createService: TServiceFactoryFn<TName>): this {
    // if (this.services.has(name) || this.serviceFactories.has('name')) throw new Error('Service was registered');

    this.serviceFactories.set(name, createService);
    return this;
  }
}

export interface IServicesProvider {
  requireService<TName extends TServiceNames>(name: TName): InferServiceType<TName>;
  requireServiceAccessor<TName extends TServiceNames>(name: TName): () => InferServiceType<TName>;
}

export interface IServicesCollection extends IServicesProvider {
  addSingleton<TName extends TServiceNames>(name: TName, service: InferServiceType<TName>): this;
  addSingletonFactory<TName extends TServiceNames>(name: TName, createService: TServiceFactoryFn<TName>): this;
}

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    services: IServicesProvider;
  }
}

export const di: IServicesCollection = new ServicesCollection();

di.addSingleton('services', di);

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.di = di;

  // eslint-disable-next-line no-console
  console.log('DiEz available from console: di', di);
}

export function requireService<TName extends TServiceNames>(name: TName): InferServiceType<TName> {
  return di.requireService(name);
}

export function requireServiceAccessor<TName extends TServiceNames>(name: TName): () => InferServiceType<TName> {
  return di.requireServiceAccessor(name);
}

export function useService<TName extends TServiceNames>(name: TName): InferServiceType<TName> {
  const serviceRef = useRef<InferServiceType<TName>>();

  if (!serviceRef.current) {
    serviceRef.current = requireService(name);
  }

  return serviceRef.current;
}

export function useServiceAccessor<TName extends TServiceNames>(name: TName): () => InferServiceType<TName> {
  const serviceRef = useRef<() => InferServiceType<TName>>();

  if (!serviceRef.current) {
    serviceRef.current = requireServiceAccessor(name);
  }

  return serviceRef.current;
}
