import { v4 as uuidv4 } from 'uuid';

import { requireServiceAccessor } from 'src/packages/di';

import type { ICollapseEntity } from 'src/pages/wells/features/well-collapse/WellCollapse.entity';
import type { WellItemEntity } from 'src/pages/wells/features/well-list-widget/WellCollapse';

type TWellsCollapseInitialData = {
  attrName: string;
  name: string;
  useCollapseTranslation: boolean;
  items: WellItemEntity[];
};

export class WellsCollapseEntity implements ICollapseEntity<WellItemEntity> {
  private readonly attrName: string;
  private readonly _name: string;
  private readonly useCollapseTranslation: boolean;

  readonly $$instanceUuid = uuidv4();
  readonly items;

  constructor(
    { attrName, name, items, useCollapseTranslation }: TWellsCollapseInitialData,
    private readonly i18 = requireServiceAccessor('i18')
  ) {
    this.attrName = attrName;
    this._name = name;
    this.items = items;
    this.useCollapseTranslation = useCollapseTranslation;
  }

  get name(): string {
    const key = `${this.attrName}.${this._name}`;

    if (this.useCollapseTranslation) {
      return this.i18().t(`Interpretations:${key}`);
    }

    return this._name;
  }

  get id(): string {
    return this._name;
  }
}
