import i18 from 'src/app/i18n';

import type { Ii18Service } from './I18NextStore';
import type { IServicesCollection } from 'src/packages/di';

import { I18NextStore } from './I18NextStore';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    i18: Ii18Service;
  }
}

export async function addI18Service(di: IServicesCollection) {
  const service = new I18NextStore(i18);
  await service.init();

  di.addSingleton('i18', service);
}
