import { flow, makeObservable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { IServicesCollection } from 'src/packages/di';

export namespace Auth {
  export type UserInfo = {
    name: string;
    sub: string;
  };

  export const isUserInfo = (object: unknown): object is UserInfo =>
    typeof object === 'object' && !!object && 'sub' in object && 'preferred_username' in object;
}

export interface IAuthService {
  userInfo: Auth.UserInfo;
  logout(): void;
}

export class AuthStore {
  readonly userInfo: Auth.UserInfo;

  constructor(
    userInfo: Auth.UserInfo,
    private readonly userService = requireService('userService'),
    private readonly notifications = requireService('notifications')
  ) {
    this.notifications = notifications;
    this.userInfo = userInfo;
    makeObservable(this);
  }

  @flow.bound
  async *logout(): Promise<void> {
    try {
      await this.userService.logout();
      yield;
    } catch (error) {
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToSignOut');
    } finally {
      window.location.reload();
    }
  }
}

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    authStore: AuthStore;
  }
}

export function addAuthStore(di: IServicesCollection, userInfo: Auth.UserInfo) {
  di.addSingleton('authStore', new AuthStore(userInfo));
}
