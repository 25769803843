import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import type { WellLogsWidgetStore } from '../../../well-logs-widget/WellLogsWidget.store';

import settingsStyles from '../../SettingsSidebar.module.scss';

import { ViewSettings } from './components/view-settings/ViewSettings';

import styles from './ConfigTab.module.scss';

type ConfigTabProps = {
  isOpen: boolean;
  isOpenedSettings: boolean;
  store: WellLogsWidgetStore;
};

export const ConfigTab = observer(function ConfigTab({ isOpen, isOpenedSettings, store }: ConfigTabProps) {
  const [tracksOverloadState] = useState(() => store.wellLogsAdapter.getTracksOverloadState());
  const wellLogsWidget = store.wellLogsEntity.state;
  const adapter = store.wellLogsAdapter;

  useEffect(() => {
    if (!tracksOverloadState || isOpenedSettings) return;

    adapter.saveTracksOverloadState(tracksOverloadState);
  }, [isOpenedSettings, adapter, tracksOverloadState]);

  if (!tracksOverloadState || !wellLogsWidget) {
    return null;
  }

  return (
    <div className={clsx(settingsStyles.tab, styles.configTab, isOpen && settingsStyles.tab_active)}>
      <ViewSettings adapter={adapter} wellLogsWidget={wellLogsWidget} tracksOverloadState={tracksOverloadState} />
    </div>
  );
});
