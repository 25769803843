import { WellLogsWidgetState, WellLogWidgetObservableAdapter } from '@go-widgets/well-logs-widget';
import { action, flow, makeObservable, observable, reaction } from 'mobx';

import { requireService, requireServiceAccessor } from 'src/packages/di';
import { equalsDeep } from 'src/packages/shared/utils/equalsDeep';

import type { TUserMobilePreferences } from '../../api/UserMobilePreferences.api';
import type { InitParams, WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { GlobalConfig } from '@profgeosoft-ui/react';

import { WellLogsWidgetEntity } from './WellLogsWidget.entity';
import { getCorrectLocale } from './WellLogsWidget.utils';

export class WellLogsWidgetStore {
  readonly wellLogsEntity: WellLogsWidgetEntity;
  readonly wellLogsAdapter: WellLogWidgetObservableAdapter;

  private readonly initParams: InitParams;
  private onPreferencesUpdate: (preferences: TUserMobilePreferences) => void;

  @observable private stateParams: Partial<WidgetStateParams> = {};
  @observable isLoading: boolean = false;
  @observable isExitFullscreenFetching: boolean = false;
  @observable isReady: boolean = false;
  @observable statusBarValue?: number | null;
  @observable hasError: boolean = false;

  constructor(
    wellId: number,
    wellIndexType: WellIndexType,
    stateParams: Partial<WidgetStateParams>,
    onPreferencesUpdate: (preferences: TUserMobilePreferences) => void,
    private readonly theme = requireService('theme'),
    private readonly stompPublisherService = requireService('stompPublisherService'),
    private readonly notifications = requireService('notifications'),
    private readonly language = requireService('language'),
    private readonly wellLogsTemplateService = requireService('wellLogsTemplateService'),
    private readonly getAuthService = requireServiceAccessor('authStore'),
    private readonly screen = requireService('screen'),
    private readonly apiService = requireService('apiService'),
    private readonly classOfUnitsService = requireService('classOfUnitsService')
  ) {
    this.onPreferencesUpdate = onPreferencesUpdate;
    this.wellLogsEntity = new WellLogsWidgetEntity({
      stateParams: {
        templateId: 1,
      },
      wellIndexType,
      isEditMode: false,
      wellId,
    });

    const wellLogsTemplatesManager = this.wellLogsTemplateService.getTemplatesManager(wellIndexType);

    this.initParams = {
      legacyAxiosInstance: this.apiService.legacyAgent,
      index: wellIndexType,
      stompPublisher: this.stompPublisherService.stompPublisher,
      axiosInstance: this.apiService.wellLogsWidgetAgent,
      userData: {
        sub: this.getAuthService().userInfo.sub,
      },
      dashboardService: wellLogsTemplatesManager.api,
      templatesManager: wellLogsTemplatesManager.templatesManager,
      userSettingsManager: wellLogsTemplatesManager.userSettings,
      widgetType: 'mobile',
      withWellboreSorting: false,
      classOfUnitsService,
    };

    this.stateParams = stateParams;

    const newState = this.getNewState(wellId, screen.isPortraitOrientation, stateParams, onPreferencesUpdate);

    this.wellLogsEntity.setState(newState);
    this.wellLogsAdapter = new WellLogWidgetObservableAdapter(newState);

    makeObservable(this);
  }

  @action.bound
  enableEditMode(): void {
    this.wellLogsEntity.setFullscreen(true);
    this.wellLogsEntity.state?.enterEditMode();
  }

  @flow.bound
  async *exitFullScreen(themeConfig: GlobalConfig) {
    if (this.isExitFullscreenFetching) return;

    this.isExitFullscreenFetching = true;

    const enableToClose = await this.wellLogsEntity.state?.exitEditModeAsync(themeConfig);

    yield;

    if (enableToClose) {
      this.wellLogsEntity.setFullscreen(false);
    }

    this.isExitFullscreenFetching = false;
  }

  handleExport = (): void => {
    this.wellLogsEntity.state?.setExportsOpen(true);
  };

  destroy = (): void => {
    this.wellLogsEntity.state?.destroy();
  };

  @action.bound
  setWellId(well: number | null): void {
    this.wellLogsEntity.setWellId(well);
  }

  @action.bound
  private setReady(value: boolean): void {
    this.isReady = value;
  }

  @action.bound
  private getNewState(
    wellId: number,
    isPortraitOrientation: boolean,
    stateParams: Partial<WidgetStateParams>,
    onPreferencesUpdate: (preferences: TUserMobilePreferences) => void
  ): WellLogsWidgetState {
    return new WellLogsWidgetState(
      this.initParams,
      {
        wellId,
        width: 500,
        height: 500,
        locale: getCorrectLocale(this.language.language),
        theme: this.theme.theme,
        fullScreen: false,
        deviceOrientation: isPortraitOrientation ? 'vertical' : 'horizontal',
      },
      stateParams,
      {
        onEditModeChanged: (state) => {
          this.wellLogsEntity.setEditMode(state);
        },
        onInitializedSuccess: () => {
          this.setReady(true);
        },
        onInitializationFailed: (_, error) => {
          this.setReady(false);

          console.error(error);
          this.notifications.showErrorMessageT('errors:failedToInitWidget');
        },
        onInternalStateChanged: (state) => {
          this.stateParams = state;
          this.wellLogsEntity.setStateParams(state);
        },
      }
    );
  }

  effect = () => {
    const disposeStateTheme = reaction(
      () => this.theme.theme,
      (theme) => {
        this.wellLogsEntity.state?.setAppParams({
          theme,
        });
      }
    );

    const disposeStateLanguage = reaction(
      () => this.language.language,
      (language) => {
        this.wellLogsEntity.state?.setAppParams({
          locale: getCorrectLocale(language),
        });
      }
    );

    const disposeStateOrientation = reaction(
      () => this.screen.isPortraitOrientation,
      (isPortraitOrientation) => {
        this.wellLogsEntity.state?.setAppParams({
          deviceOrientation: isPortraitOrientation ? 'vertical' : 'horizontal',
        });
      }
    );

    const disposeStateParams = reaction(
      () => this.stateParams,
      (stateParams, prevStateParams) => {
        if (!equalsDeep(stateParams, prevStateParams)) {
          this.onPreferencesUpdate({ stateParams });
        }
      }
    );

    return () => {
      disposeStateTheme();
      disposeStateLanguage();
      disposeStateOrientation();
      disposeStateParams();
      this.destroy();
    };
  };
}
