import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import type {
  SourcesOverload,
  TrackOverload,
} from '@go-widgets/well-logs-widget/dist/stores/WidgetTracksSettings/TrackOverload';

import { Curve } from '../curve/Curve';

import styles from './Curves.module.scss';

type CurvesProps = {
  curves: SourcesOverload[];
  track: TrackOverload;
  align?: 'left' | 'right';
};

export const Curves = observer(function Sources({ curves, track, align }: CurvesProps) {
  return (
    <div className={clsx(styles.curves, align === 'right' && styles.curves_right)}>
      <SortableContext strategy={rectSortingStrategy} id={track.id} items={curves}>
        {curves.map((curve) => (
          <Curve track={track} key={curve.id} curve={curve} />
        ))}
      </SortableContext>
    </div>
  );
});
