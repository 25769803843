import { HomeLight as HomeIcon, DrillingRigCustom as DrillingIcon } from '@profgeosoft-ui/icons';
import { Field, RadioButtonGroup, Switch } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { WellLogWidgetObservableAdapter } from '@go-widgets/well-logs-widget';

import settingsStyles from '../../SettingsSidebar.module.scss';
import { headerVariants } from '../../utils';

import styles from './CommonTab.module.scss';

type CommonTabProps = {
  isOpen: boolean;
  adapter: WellLogWidgetObservableAdapter;
};

export const CommonTab = observer(function CommonTab({ isOpen, adapter }: CommonTabProps) {
  const { t } = useTranslation('settings');

  return (
    <div className={clsx(settingsStyles.tab, styles.commonTab, isOpen && settingsStyles.tab_active)}>
      <Field label={t('commonTab.header')} className={styles.field}>
        <RadioButtonGroup
          size="m"
          items={headerVariants()}
          value={adapter.extendedHeader ? 'on' : 'off'}
          onChange={(value) => {
            adapter.setExtendedHeader(value === 'on');
          }}
          className={styles.radioButtonGroup}
        />
      </Field>

      {adapter.timeZones && (
        <Field label={t('commonTab.timeZone')} className={styles.field}>
          <RadioButtonGroup
            size="m"
            items={[
              {
                label: (
                  <>
                    <HomeIcon className={styles.timeZoneIcon} />
                    {adapter.timeZones.userTimeZone.title}
                  </>
                ),
                value: adapter.timeZones.userTimeZone.value,
              },
              {
                label: (
                  <>
                    <DrillingIcon className={styles.timeZoneIcon} />
                    {adapter.timeZones.wellTimeZone.title}
                  </>
                ),
                value: adapter.timeZones.wellTimeZone.value,
              },
            ]}
            value={adapter.timeZones?.selected}
            onChange={(value) => adapter.setTimeZone(value)}
            className={styles.radioButtonGroup}
          />
        </Field>
      )}

      <div className={styles.switchContainer}>
        <p>{t('commonTab.hideNoDataCurves')}</p>
        <Switch
          orientation="horizontal"
          checked={adapter.hideNoDataCurves}
          onChange={(value) => adapter.setHideNoDataCurves(value)}
          className={styles.curveSwitcher}
          containerClassName={styles.curveSwitcherContainer}
          knobClassName={styles.curveSwitcherKnob}
        />
      </div>
    </div>
  );
});
