import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { THEMES } from 'src/app/theme/consts';
import { useService } from 'src/packages/di';
import { Switcher } from 'src/packages/ui-kit/switcher/Switcher';

import { themeItems } from './ThemeSwitcher.consts';

type Props = {
  orientation: 'vertical' | 'horizontal';
  isCompact: boolean;
};

export const ThemeSwitcher = observer(function ThemeSwitcher({ orientation = 'horizontal', isCompact }: Props) {
  const theme = useService('theme');
  const { isPortraitOrientation } = useService('screen');

  useEffect(() => {
    if (Capacitor.getPlatform() === 'web') return;

    const setStatusBarStyle = async () => {
      await StatusBar.setStyle({ style: theme.theme === THEMES.light ? Style.Light : Style.Dark });
    };

    setStatusBarStyle();
  }, [theme.theme]);

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'android') return;

    const hideStatusBar = async () => {
      await StatusBar.hide();
    };

    const showStatusBar = async () => {
      await StatusBar.show();
    };

    if (!isPortraitOrientation) {
      hideStatusBar();
    } else {
      showStatusBar();
    }

    NavigationBar.hide();
  }, [theme.theme, isPortraitOrientation]);

  return (
    <div>
      <Switcher
        items={themeItems}
        orientation={orientation}
        isActive={theme.theme === THEMES.light}
        isCompact={isCompact}
        onToggle={theme.toggle}
      />
    </div>
  );
});
