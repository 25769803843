import { action, makeObservable, observable } from 'mobx';

import i18n from 'src/app/i18n';
import { PRIMARY_LANG, SECONDARY_LANG, languageSwitcherItems } from 'src/packages/language/consts';

import type { ILanguageService } from './ILanguageService';
import type { TSliderItem } from 'src/packages/ui-kit/switcher/Switcher';

export class LanguageStore implements ILanguageService {
  @observable language: string;
  @observable isLanguageSwitcherHidden = false;

  constructor() {
    this.language = i18n.language;

    makeObservable(this);
  }

  private _setLanguage(lan: string) {
    this.language = lan;
  }

  get languages(): TSliderItem[] {
    const languages = [languageSwitcherItems[PRIMARY_LANG]];

    if (SECONDARY_LANG && !!languageSwitcherItems[SECONDARY_LANG]) {
      languages.push(languageSwitcherItems[SECONDARY_LANG]);
    }

    return languages;
  }

  @action.bound
  toggle(): void {
    if (!PRIMARY_LANG || !SECONDARY_LANG) {
      return;
    }

    this._setLanguage(this.language === PRIMARY_LANG ? SECONDARY_LANG : PRIMARY_LANG);
    i18n.changeLanguage(this.language);
  }

  @action.bound
  init(): void {
    if (!!PRIMARY_LANG && !SECONDARY_LANG) {
      i18n.changeLanguage(PRIMARY_LANG);
      this._setLanguage(PRIMARY_LANG);
      this.isLanguageSwitcherHidden = true;
    }
  }
}
