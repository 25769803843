import { throwApiError } from 'src/api/utils';

import type { TDictObject, TJoinResponse, TRefQuery, TValuesInterpretations } from '../types';
import type { AxiosResponse } from 'axios';
import type { ApiService } from 'src/api/ApiService';
import type { IServicesCollection } from 'src/packages/di';

export class DirectoriesApi {
  constructor(private readonly apiService: ApiService) {}

  fetchDirectory = async <T = TDictObject[]>(dirName: string): Promise<T> => {
    try {
      const res = await this.apiService.goStorageAgent.get<T>(`object/list/${dirName}`);
      return res.data;
    } catch (error) {
      throwApiError(error);
    }
  };

  async fetchJoinedDirectory(refQuery: TRefQuery, flatResponse = false): Promise<TJoinResponse[]> {
    try {
      const res = await this.apiService.goStorageAgent.post<TRefQuery, AxiosResponse<TJoinResponse[]>>(
        'object/select',
        refQuery,
        {
          params: {
            flatResponse,
          },
        }
      );
      return res.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  fetchSettingsLabels = async (locale?: string): Promise<Record<string, string>> => {
    const headers = locale
      ? {
          'accept-language': locale,
        }
      : void 0;

    try {
      const { data: settingsLabels } = await this.apiService.goStorageAgent.get('settings/labels', {
        headers,
      });

      return settingsLabels;
    } catch (e) {
      throwApiError(e);
    }
  };

  fetchValuesInterpretations = async (locale?: string): Promise<TValuesInterpretations> => {
    const headers = locale
      ? {
          'accept-language': locale,
        }
      : void 0;

    try {
      const { data: valuesInterpretations } = await this.apiService.goStorageAgent.get(
        'settings/value_interpretations',
        {
          headers,
        }
      );

      return valuesInterpretations;
    } catch (e) {
      throwApiError(e);
    }
  };
}

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    directoriesApi: DirectoriesApi;
  }
}

export function addDirectoriesApiService(di: IServicesCollection) {
  const apiService = di.requireService('apiService');

  di.addSingleton('directoriesApi', new DirectoriesApi(apiService));
}
