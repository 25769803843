import {
  LineUpLight as AnalysisIcon,
  BackLight as BackIcon,
  ExpandDownLight as CloseIcon,
  ChartAltLight as CommentIcon,
  InfoRoundLight as InfoIcon,
  TrackLightCustom as SensorIcon,
} from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pastIcon, pastIconActive } from './consts';

import styles from './SectionsNav.module.scss';

type SectionsNavProps = {
  isOpened: boolean;
  onClose: VoidFunction;
};

export const SectionsNav = observer(function SectionsNav({ isOpened, onClose }: SectionsNavProps) {
  const { t } = useTranslation('wellLogs');
  const navigate = useNavigate();

  const sections = [
    {
      id: 1,
      title: t('wellLogs:sections.commonInfo'),
      to: '',
      preIcon: <InfoIcon className={styles.linkIcon} />,
      pastIcon,
      pastIconActive,
      disabled: true,
    },
    {
      id: 2,
      title: t('wellLogs:sections.sensorData'),
      to: '',
      preIcon: <SensorIcon className={styles.linkIcon} />,
      pastIcon,
      pastIconActive,
      disabled: false,
    },
    {
      id: 3,
      title: t('wellLogs:sections.comments'),
      to: '',
      preIcon: <CommentIcon className={styles.linkIcon} />,
      pastIcon,
      pastIconActive,
      disabled: true,
    },
    {
      id: 4,
      title: t('wellLogs:sections.analysis'),
      to: '',
      preIcon: <AnalysisIcon className={styles.linkIcon} />,
      pastIcon,
      pastIconActive,
      disabled: true,
    },
  ];

  const activeId = 2;

  return (
    <div className={clsx(styles.sections, isOpened && styles.sections_opened)}>
      <div className={styles.nav}>
        <h3 className={styles.title}>{t('sections.title')}</h3>
        {sections.map((link) => {
          const isActive = link.id === activeId;

          return (
            <Button
              className={clsx(styles.link, isActive && styles.link_active)}
              key={link.id}
              onClick={() => navigate(link.to)}
              disabled={link.disabled}
            >
              {link.preIcon}
              <span className={styles.linkText}>{link.title}</span>
              {isActive ? link.pastIconActive : link.pastIcon}
            </Button>
          );
        })}
        <div className={styles.delimiter}></div>
        <Button onClick={() => navigate('/wells')} className={clsx(styles.link, styles.linkBack)}>
          <BackIcon className={styles.linkIcon} />
          {t('sections.backToWellsList')}
        </Button>
      </div>
      <Button className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </Button>
    </div>
  );
});
