import { Device } from '@capacitor/device';
import { computed, makeObservable } from 'mobx';

import { getAccessToken, getIsTokenExpired, getRefreshToken, removeTokens, setTokens } from 'src/app/auth/auth';

import type { KeycloakConfig, KeycloakInitOptions } from '../../../packages/keycloak-adapter/keycloak';
import type { ApiService } from 'src/api/ApiService';
import type { Globals } from 'src/api/globals';
import type { IServicesCollection } from 'src/packages/di';

import { default as KeycloakJS } from '../../../packages/keycloak-adapter/keycloak';
import { Auth } from '../AuthStore';

export class UserService {
  private readonly keycloakConfig: KeycloakConfig;
  private readonly keycloakAgent;

  constructor(private readonly globals: Globals, private readonly apiService: ApiService) {
    this.keycloakConfig = {
      realm: this.globals.KEYCLOAK_REALM_NAME,
      clientId: this.globals.KEYCLOAK_CLIENT_ID,
      url: this.globals.API_AUTH_URL,
    };

    this.keycloakAgent = new KeycloakJS(this.keycloakConfig);
    makeObservable(this);
  }

  @computed
  get mobileKeycloakInitParams(): KeycloakInitOptions {
    return {
      flow: 'standard',
      scope: 'openid offline_access',
      token: getAccessToken() || undefined,
      refreshToken: getRefreshToken() || undefined,
      adapter: 'capacitor',
      responseMode: 'query',
      onLoad: 'login-required',
      redirectUri: 'e-perform://home',
    };
  }

  @computed
  get webKeycloakInitParams(): KeycloakInitOptions {
    return {
      flow: 'standard',
      scope: 'openid offline_access',
      token: getAccessToken() || undefined,
      refreshToken: getRefreshToken() || undefined,
      adapter: 'default',
    };
  }

  @computed
  get mobileKeycloakInitWithCheckParams(): KeycloakInitOptions {
    return {
      flow: 'standard',
      onLoad: 'login-required',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      scope: 'openid offline_access',
      refreshToken: getRefreshToken() || undefined,
      adapter: 'capacitor',
      responseMode: 'query',
      redirectUri: 'e-perform://home',
    };
  }

  @computed
  get webKeycloakInitWithCheckParams(): KeycloakInitOptions {
    return {
      flow: 'standard',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      scope: 'openid offline_access',
      refreshToken: getRefreshToken() || undefined,
      adapter: 'default',
    };
  }

  async initUserService(): Promise<void> {
    const isWebPlatform = (await Device.getInfo()).platform === 'web';

    if (isWebPlatform) {
      await this.keycloakAgent.init(this.webKeycloakInitParams);
    } else {
      await this.keycloakAgent.init(this.mobileKeycloakInitParams);
    }
  }

  async initUserServiceWithCheck(): Promise<boolean> {
    try {
      const isWebPlatform = (await Device.getInfo()).platform === 'web';

      if (isWebPlatform) {
        await this.keycloakAgent.init(this.webKeycloakInitWithCheckParams);
      } else {
        await this.keycloakAgent.init(this.mobileKeycloakInitWithCheckParams);
      }

      if (this.keycloakAgent.authenticated && this.keycloakAgent.token) {
        setTokens(this.keycloakAgent.token, this.keycloakAgent.refreshToken);

        return this.keycloakAgent.authenticated;
      }

      if (this.keycloakAgent.refreshToken) {
        const accessToken = await this.updateToken();

        setTokens(accessToken);

        return this.keycloakAgent.authenticated || false;
      }

      await this.login();
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async updateToken(): Promise<string | undefined> {
    try {
      const refreshed = await this.keycloakAgent.updateToken(-1);

      const { token, refreshToken } = this.keycloakAgent;

      if (refreshed && token) {
        setTokens(token, refreshToken);

        return token;
      } else {
        throw new Error('Failed to refresh token or new token is undefined.');
      }
    } catch (e) {
      await this.login();
    }
  }

  async login(): Promise<void> {
    removeTokens();
    await this.keycloakAgent.login();
  }

  async getUserData(): Promise<{} | undefined> {
    const { data } = await this.apiService.userAgent.get(
      `/realms/${this.globals.KEYCLOAK_REALM_NAME}/protocol/openid-connect/userinfo`
    );

    return data;
  }

  async logout(): Promise<void> {
    removeTokens();
    await this.keycloakAgent.logout();
  }

  async auth(): Promise<Auth.UserInfo | undefined> {
    try {
      if (getIsTokenExpired()) {
        await this.initUserServiceWithCheck();
      } else {
        await this.initUserService();
      }

      const userInfo = await this.getUserData();

      if (Auth.isUserInfo(userInfo)) {
        return userInfo;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    userService: UserService;
  }
}

export function addUserService(di: IServicesCollection) {
  const globals = di.requireService('globals');
  const apiService = di.requireService('apiService');

  di.addSingleton('userService', new UserService(globals, apiService));
}
