import { throwApiError } from 'src/api/utils';
import { requireService, requireServiceAccessor } from 'src/packages/di';

import type { TWellListView } from 'src/api-types/wells.types';

export class WorkspaceApi {
  constructor(
    private readonly auth = requireServiceAccessor('authStore'),
    private readonly apiService = requireService('apiService')
  ) {}

  async fetchWellListView(): Promise<TWellListView> {
    try {
      const view = await this.apiService.goStorageAgent.get<TWellListView>('view/GOdashboard_WellDirectory');

      return view.data;
    } catch (error) {
      throwApiError(error);
    }
  }
}
