import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { MainLayout } from 'src/layouts/main-layout/MainLayout';
import { isNumber } from 'src/packages/shared/utils/common';

import { WellLogsWidget } from './features/well-logs-widget/WellLogsWidget';
import { WellPageStore } from './WellPage.store';

export const WellPage = observer(function WellPage() {
  const [store] = useState<WellPageStore>(() => new WellPageStore());

  const { isLoading, navList, stateParams, wellIndexType, onChangeWellType, updatePreferences } = store;

  useEffect(() => {
    store.init();
  }, [store]);

  const { wellId: wellIdParam } = useParams();
  const wellId = Number(wellIdParam);

  const renderContent = () => {
    if (isLoading || !navList || !wellIndexType) {
      return <Loader size="l" />;
    }

    return (
      <WellLogsWidget
        wellId={wellId}
        wellIndexType={wellIndexType}
        navList={navList}
        stateParams={stateParams}
        onWellTypeChange={onChangeWellType}
        onPreferencesUpdate={updatePreferences}
      />
    );
  };

  if (!isNumber(wellId)) {
    return <Navigate to="/wells" replace={true} />;
  }

  return <MainLayout>{renderContent()}</MainLayout>;
});
