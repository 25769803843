import { BookmarkFill, BookmarkLight } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import type { Template, WellLogWidgetObservableAdapter } from '@go-widgets/well-logs-widget';

import styles from './TemplatesTab.module.scss';

type TemplatesItemProps = {
  template: Template;
  adapter: WellLogWidgetObservableAdapter;
};

export const TemplatesItem = observer(function Template({ template, adapter }: TemplatesItemProps) {
  const onSetTemplate = () => {
    if (template.id) {
      adapter.setTemplate(template.id);
    }
  };

  const onSetDefaultTemplate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (template.id) {
      adapter.setAsDefaultTemplate(template.id);
    }
  };

  return (
    <button
      onClick={onSetTemplate}
      className={clsx(
        styles.templateButton,
        adapter.templates?.selected === template.id && styles.templateButton_active
      )}
    >
      <span>{template.name}</span>
      <div onClick={onSetDefaultTemplate} className={styles.templateButtonDefault}>
        {adapter.templates?.default === template.id ? (
          <BookmarkFill className={styles.templateButtonIconActive} />
        ) : (
          <BookmarkLight className={styles.templateButtonIcon} />
        )}
      </div>
    </button>
  );
});
