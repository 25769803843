import { ExpandDownLight as ArrowIcon } from '@profgeosoft-ui/icons';
import { Collapse } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import type { IWellCollapseItemEntity } from './components/well-collapse-item/WellCollapseItem.entity';
import type { ICollapseEntity } from './WellCollapse.entity';
import type { ReactNode, Ref, Key } from 'react';

import { CollapseItem } from './components/well-collapse-item/WellCollapseItem';

import styles from './WellCollapse.module.scss';

type Props<T extends IWellCollapseItemEntity> = {
  collapses: ICollapseEntity<T>[];
  refAt(key: number): Ref<HTMLDivElement>;
  activeKey: string[];
  renderIcon?: (item: T) => ReactNode;
  onChange(key: Key[] | undefined): void;
  renderButtons(item: T): ReactNode;
};

export const WellCollapse = observer(function WellCollapse<T extends IWellCollapseItemEntity>({
  collapses,
  refAt,
  renderButtons,
  activeKey,
  renderIcon,
  onChange,
}: Props<T>) {
  const navigate = useNavigate();

  const handleRedirect = (id: number): void => {
    navigate(`/wells/${id}`);
  };

  return (
    <Collapse activeKeys={activeKey} collapseClassname={styles.wrapper} onChange={onChange}>
      {collapses.map((collapse) => (
        <Collapse.Panel
          panelKey={collapse.id}
          key={collapse.id}
          header={
            <div className={styles.panelHeader}>
              <ArrowIcon
                className={clsx(
                  styles.panelHeaderArrow,
                  activeKey.includes(collapse.id) && styles.panelHeaderArrowDown
                )}
              />
              <p className={styles.panelHeaderText}>{collapse.name}</p>
            </div>
          }
        >
          <ul className={styles.list}>
            {collapse.items.map((item) => (
              <CollapseItem<T>
                key={item.id}
                renderIcon={renderIcon}
                renderButtons={() => renderButtons(item)}
                refAt={refAt}
                item={item}
                onClick={() => handleRedirect(item.id)}
              />
            ))}
          </ul>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
});
