import { ExpandDownLight as CloseIcon } from '@profgeosoft-ui/icons';
import { Button, Radio, RadioButtonGroup } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState, type TouchEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { WellLogsWidgetStore } from '../../../well-logs-widget/WellLogsWidget.store';

import { wellboreTabItems } from './utils';
import { WellboreRunStore } from './WellboreRun.store';

import styles from './WellboreRun.module.scss';

type WellboreRunProps = {
  store: WellLogsWidgetStore;
  isOpened: boolean;
  onClose: VoidFunction;
};

export const WellboreRun = observer(function WellboreRun({
  store: wellLogsWidgetStore,
  isOpened,
  onClose,
}: WellboreRunProps) {
  const { t } = useTranslation('wellLogs');
  const [store] = useState(() => new WellboreRunStore(wellLogsWidgetStore));
  const [tabIndex, setTabIndex] = useState<number>(1);

  const { wellboresList, runNumbersList, selectedWellbore, selectedRunNumber, setRunNumber, setWellbore } = store;

  const handleChangeTabIndex = (value: number): void => {
    setTabIndex(value);
  };

  const onTouchEnd = (event: TouchEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  };

  return (
    <div className={clsx(styles.barrelFlight, isOpened && styles.barrelFlight_opened)}>
      <div className={styles.tabs}>
        <RadioButtonGroup
          variant="flat"
          items={wellboreTabItems()}
          value={tabIndex}
          className={styles.switchContainer}
          itemClassName={styles.switchButton}
          onChange={handleChangeTabIndex}
        />
        <div className={styles.tabsWrapper} onTouchEnd={onTouchEnd}>
          <div className={styles.tabsBody}>
            {tabIndex === 1 &&
              wellboresList.map((bore) => (
                <Radio
                  name="barrel"
                  value={bore.id}
                  key={bore.id}
                  className={clsx(styles.radio, bore.id === selectedWellbore && styles.radio_active)}
                  checked={bore.id === selectedWellbore}
                  onChange={() => setWellbore(bore)}
                >
                  {bore.name}
                </Radio>
              ))}
            {tabIndex === 2 && (
              <>
                <Radio
                  name="flight"
                  value={0}
                  className={clsx(styles.radio, !selectedRunNumber && styles.radio_active)}
                  checked={!selectedRunNumber}
                  onChange={() => setRunNumber(0)}
                >
                  {t('mainRun')}
                </Radio>
                {!!runNumbersList.length && (
                  <>
                    <div className={styles.delimiter}></div>
                    {runNumbersList.map((run) => (
                      <Radio
                        name="flight"
                        value={run}
                        key={run}
                        className={clsx(styles.radio, run === selectedRunNumber && styles.radio_active)}
                        checked={run === selectedRunNumber}
                        onChange={() => setRunNumber(run)}
                      >
                        {`${t('run')} ${run}`}
                      </Radio>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Button className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </Button>
    </div>
  );
});
