import { NotificationContainer } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { type PropsWithChildren } from 'react';

import { Sidebar } from 'src/components/sidebar/Sidebar';

import styles from './MainLayout.module.scss';

type Props = PropsWithChildren<{
  header?: React.ReactNode;
  showSidebar?: boolean;
}>;

export const MainLayout = observer(function MainLayout({ children, showSidebar = true, header }: Props) {
  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.contentWrapper}>
        {header && (
          <div className={styles.headerWrapper}>
            <header className={styles.header}>{header}</header>
          </div>
        )}
        {children}
      </div>

      {showSidebar && <Sidebar />}

      <NotificationContainer />
    </div>
  );
});
