import { Collapse } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { WellLogWidgetObservableAdapter } from '@go-widgets/well-logs-widget';
import type { Key } from 'react';

import settingsStyles from '../../SettingsSidebar.module.scss';

import { TemplatesItem } from './TemplatesItem';

import styles from './TemplatesTab.module.scss';

type TemplatesTabProps = {
  isOpen: boolean;
  adapter: WellLogWidgetObservableAdapter;
};

export const TemplatesTab = observer(function TemplatesTab({ isOpen, adapter }: TemplatesTabProps) {
  const { t } = useTranslation('settings');

  const [activeKeys, setActiveKeys] = useState<Key[]>(['public', 'personal']);

  return (
    <div
      className={clsx(settingsStyles.tab, styles.tabTemplates, isOpen && settingsStyles.tab_active)}
      style={{ paddingRight: 0 }}
    >
      {/* <div className={styles.overflowContainer}> */}
      <Collapse activeKeys={activeKeys} onChange={setActiveKeys} collapseClassname={styles.collapse}>
        <Collapse.Panel panelKey="public" header={t('templatesTab.public')} className={styles.collapsePanel}>
          {adapter.templates.public?.map((template) => (
            <TemplatesItem adapter={adapter} template={template} key={template.id} />
          ))}
        </Collapse.Panel>
        <Collapse.Panel panelKey="personal" header={t('templatesTab.personal')} className={styles.collapsePanel}>
          {adapter.templates.personal?.map((template) => (
            <TemplatesItem adapter={adapter} template={template} key={template.id} />
          ))}
        </Collapse.Panel>
      </Collapse>
      {/* </div> */}
    </div>
  );
});
