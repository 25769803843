import { observer } from 'mobx-react-lite';

import { ReactComponent as LogoEPerform } from 'src/packages/assets/img/logo-eperform.svg';

import styles from './Logo.module.scss';

export const Logo = observer(function Logo() {
  return (
    <div className={styles.logoWrapper}>
      <LogoEPerform className={styles.logoImg} />
    </div>
  );
});
