import { EditLight as EditIcon, MenuAltLight as MenuIcon } from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { useService } from 'src/packages/di';

import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { TNavItem } from '../../types';
import type { WellPageNavStore } from '../../WellPageNav.store';

import { ShortNavItem } from '../short-nav-item/ShortNavItem';

import styles from './ShortNav.module.scss';

type ShortNavProps = {
  navList: TNavItem[];
  store: WellPageNavStore;
  onOpenSections: VoidFunction;
  onOpenWellboreRun: VoidFunction;
  onChangeWellType(value: boolean): void;
  wellIndexType: WellIndexType;
  onOpenSettings: VoidFunction;
};

export const ShortNav = observer(function ShortNav({
  store,
  navList,
  onOpenSections,
  onOpenWellboreRun,
  onChangeWellType,
  wellIndexType,
  onOpenSettings,
}: ShortNavProps) {
  const { orientation } = useService('screen');

  return (
    <div className={styles.shortNav}>
      {navList.map((nav) => (
        <ShortNavItem
          store={store}
          navItem={nav}
          orientation={orientation}
          key={nav.id}
          onOpenedWellboreRun={onOpenWellboreRun}
          onChangeWellType={onChangeWellType}
          wellIndexType={wellIndexType}
        />
      ))}
      <div className={styles.delimiter}></div>
      <Button onClick={onOpenSettings} className={styles.navButton}>
        <EditIcon />
      </Button>
      <Button onClick={onOpenSections} className={styles.navButton}>
        <MenuIcon />
      </Button>
    </div>
  );
});
