import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  ExpandDownLight as CloseIcon,
  EditLightBig as EditIcon,
  MenuAltLight as MenuIcon,
} from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { TouchEvent } from 'react';
import type { WellPageNavStore } from '../../WellPageNav.store';

import { SortableSection } from '../sortable-section/SortableSection';

import styles from './FullNav.module.scss';

type FullNavProps = {
  store: WellPageNavStore;
  isOpened: boolean;
  onClose: VoidFunction;
  onOpenSections: VoidFunction;
  onOpenWellboreRun: VoidFunction;
  onChangeWellType: (value: boolean) => void;
  wellIndexType: WellIndexType;
  onOpenSettings: VoidFunction;
};

export const FullNav = observer(function FullNav({
  store,
  isOpened,
  onClose,
  onOpenSections,
  onOpenWellboreRun,
  onChangeWellType,
  wellIndexType,
  onOpenSettings,
}: FullNavProps) {
  const { t } = useTranslation();
  const { navList, onDragOver, onDragEnd } = store;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onTouchEnd = (event: TouchEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  };

  return (
    <div className={clsx(styles.fullNav, isOpened && styles.fullNav_opened)}>
      <div className={styles.fullNavContainer}>
        <div className={styles.fullNavSortable} onTouchEnd={onTouchEnd}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableSection
              id="visible"
              items={navList.visible}
              store={store}
              onOpenedWellboreRun={onOpenWellboreRun}
              onChangeWellType={onChangeWellType}
              wellIndexType={wellIndexType}
            />
            <div className={styles.hiddenNavTitle}>{t('wellLogs:titleHiddenNav')}</div>
            <SortableSection
              id="hidden"
              items={navList.hidden}
              store={store}
              onOpenedWellboreRun={onOpenWellboreRun}
              onChangeWellType={onChangeWellType}
              wellIndexType={wellIndexType}
            />
          </DndContext>
        </div>
        <div className={styles.fullNavButtons}>
          <Button className={styles.link} onClick={onOpenSettings}>
            <EditIcon className={styles.linkIcon} />
            <span className={styles.linkText}>{t('wellLogs:editNav')}</span>
          </Button>
          <Button className={styles.link} onClick={onOpenSections}>
            <MenuIcon className={clsx(styles.linkIcon, styles.linkIconMenu)} />
            <span className={styles.linkText}>{t('wellLogs:sectionsNav')}</span>
          </Button>
        </div>
      </div>

      <Button className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </Button>
    </div>
  );
});
