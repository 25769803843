import {
  TargetLight as TargetIcon,
  EyeLight as HeaderShowIcon,
  // ViewHideLight as HeaderHideIcon,
  SlantPilotCustom as PilotIcon,
  SearchLight as SearchIcon,
  ExpandDownStopLight as AutoScrollIcon,
  TimeLight as TimeIcon,
  DepthLightCustom as DepthIcon,
} from '@profgeosoft-ui/icons';

import type { NavItemAction } from '../../types';

import styles from './ShortNavItem.module.scss';

export function getButtonIcon(action: NavItemAction, isIconActive?: boolean, value?: boolean) {
  switch (action) {
    case 'target':
      return <TargetIcon />;
    case 'search':
      return <SearchIcon />;
    case 'header':
      // return isIconActive && value ? <HeaderHideIcon /> : <HeaderShowIcon />;
      return <HeaderShowIcon />;
    case 'autoScroll':
      return <AutoScrollIcon />;
    case 'barrelFlight':
      return <PilotIcon />;
  }
}

export function getSwitchIcons() {
  return {
    left: <TimeIcon className={styles.scaleSwitcherIcon} />,
    right: <DepthIcon className={styles.scaleSwitcherIcon} />,
  };
}

export function getISOStringDateFromNumber(dateNumber: number): string {
  const date = new Date(dateNumber);
  const dateAndTime = date.toISOString().split('T');
  const time = dateAndTime[1].split(':');

  return dateAndTime[0] + 'T' + time[0] + ':' + time[1];
}
