import { observer } from 'mobx-react-lite';

import { useService } from 'src/packages/di';
import { SECONDARY_LANG } from 'src/packages/language/consts';
import { Switcher } from 'src/packages/ui-kit/switcher/Switcher';

import type { TSliderItem } from 'src/packages/ui-kit/switcher/Switcher';

type Props = {
  orientation: 'vertical' | 'horizontal';
  isCompact: boolean;
};

const checkIsTwoLanguagesPresented = (langs: TSliderItem[]): langs is [TSliderItem, TSliderItem] => {
  return langs.length === 2;
};

export const LanguageSwitcher = observer(function LanguageSwitcher({ orientation = 'horizontal', isCompact }: Props) {
  const languageService = useService('language');
  const languages = languageService.languages;

  if (languageService.isLanguageSwitcherHidden || !checkIsTwoLanguagesPresented(languages)) {
    return null;
  }

  return (
    <div>
      <Switcher
        isCompact={isCompact}
        orientation={orientation}
        isActive={languageService.language === SECONDARY_LANG}
        items={languages}
        onToggle={languageService.toggle}
      />
    </div>
  );
});
