import { computed, flow, makeObservable, observable } from 'mobx';

import type { WellEntity } from './entities/Well.entity';
import type { IFavoritesService } from './IFavoritesService';
import type { IDataSource } from '../template-builder/TemplateBuilder';

import { assert } from '../shared/utils/assert';

import { FavoritesApi } from './api/Favorites.api';

export class FavoritesDataSource implements IDataSource {
  constructor(private readonly source: { [key: string]: number[] }) {}

  hasKey(key: string): boolean {
    return key in this.source;
  }

  getValue(key: string) {
    assert(key in this.source);

    const value = this.source[key];

    assert(typeof value !== 'undefined');

    return value;
  }
}

function isFavoritesResponse(favorites: unknown): favorites is number[] {
  if (!favorites || (!!favorites && typeof favorites !== 'object')) return false;
  if (!Array.isArray(favorites)) return false;

  for (const favoriteItem of favorites) {
    if (typeof favoriteItem !== 'number') {
      return false;
    }
  }

  return true;
}

export class FavoritesWellsService implements IFavoritesService {
  @observable wells: number[] = [];
  @observable hasError: boolean = false;
  @observable favoritesObjectId?: number;

  private readonly api = new FavoritesApi();

  constructor() {
    makeObservable(this);
  }

  get wellIds(): number[] {
    return this.wells;
  }

  @computed
  get dataSource(): IDataSource {
    return new FavoritesDataSource({ favoriteWellIds: this.wellIds });
  }

  hasWell(well: WellEntity): boolean {
    return !!this.wells.find((_well) => _well === well.id);
  }

  resetError(): void {
    this.hasError = false;
  }

  @flow.bound
  async *loadFavoritesIds() {
    try {
      const favoritesResponse = await this.api.getFavorites();

      yield;

      if (favoritesResponse.length === 0) {
        const newFavoritesObject = await this.api.createFavotites();

        yield;

        this.favoritesObjectId = newFavoritesObject.id;
        this.wells = [];
      } else {
        const {
          data: { favorites },
          id,
        } = favoritesResponse[0];

        this.favoritesObjectId = id;
        this.wells = isFavoritesResponse(favorites) ? favorites : [];
      }
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
    }
  }

  @flow.bound
  async *disableFavorite(well: WellEntity) {
    if (!this.favoritesObjectId) return;

    this.hasError = false;

    try {
      this.wells.remove(well.id);
      await this.api.updateFavorites(this.favoritesObjectId, this.wellIds);

      yield;
    } catch (error) {
      yield;

      this.wells.push(well.id);

      console.error(error);
      this.hasError = true;
    }
  }

  @flow.bound
  async *enableFavorite(well: WellEntity) {
    if (!this.favoritesObjectId) return;

    this.hasError = false;

    try {
      this.wells.push(well.id);
      await this.api.updateFavorites(this.favoritesObjectId, this.wellIds);

      yield;
    } catch (error) {
      yield;

      this.wells.remove(well.id);

      console.error(error);
      this.hasError = true;
    }
  }
}
