import type { IScreenOrientationService } from './IScreenOrientationService';
import type { IServicesCollection } from 'src/packages/di';

import { ScreenOrientationService } from './ScreenOrientationService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    screen: IScreenOrientationService;
  }
}

export const addScreenOrientationService = (di: IServicesCollection) => {
  const screenOrientationService = new ScreenOrientationService();
  screenOrientationService.init();
  di.addSingleton('screen', screenOrientationService);
};
