import { Capacitor } from '@capacitor/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { useOutsideClick } from 'src/packages/shared/hooks/use-outside-click';
import { useSafeAreaView } from 'src/packages/shared/hooks/use-safe-area-view';

import type { ReactNode, TouchEvent } from 'react';

import styles from './BottomSheet.module.scss';

type BottomSheetProps = {
  isOpened: boolean;
  children: ReactNode;
  onOpened: VoidFunction;
  onClosed: VoidFunction;
  isPortraitOrientation: boolean;
  isOverlay?: boolean;
};

const MIN_SIZE_SHEET = 64;
const WIDTH_SHEET = 585;
const MIN_SWIPE_DISTANCE = 5;

const isIOS = Capacitor.getPlatform() === 'ios';
const isAndroid = Capacitor.getPlatform() === 'android';
const ANDROID_PADDING_BOTTOM = 34;
const IOS_PADDING_RIGHT = 4;

export const BottomSheet = observer(function BottomSheet({
  isOpened,
  children,
  onClosed,
  onOpened,
  isOverlay,
  isPortraitOrientation,
}: BottomSheetProps) {
  const sheetRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [sizeSheet, setSizeSheet] = useState(0);
  const [minSizeSheet, setMinSizeSheet] = useState(0);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchEndY, setTouchEndY] = useState<number | null>(null);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);

  const isOpen = isOpened && sizeSheet > minSizeSheet;

  const onTouchStart = (event: TouchEvent<HTMLDivElement>): void => {
    setTouchEndY(null);
    setTouchEndX(null);
    setTouchStartY(event.targetTouches[0].clientY);
    setTouchStartX(event.targetTouches[0].clientX);
  };

  const onTouchMove = (event: TouchEvent<HTMLDivElement>): void => {
    setTouchEndY(event.targetTouches[0].clientY);
    setTouchEndX(event.targetTouches[0].clientX);
  };

  const onTouchEnd = (): void => {
    if (!touchStartY || !touchEndY || !touchStartX || !touchEndX) return;

    const distanceY = touchStartY - touchEndY;
    const isUpSwipe = distanceY > MIN_SWIPE_DISTANCE;
    const isDownSwipe = distanceY < -MIN_SWIPE_DISTANCE;

    const distanceX = touchStartX - touchEndX;
    const isLeftSwipe = distanceX > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distanceX < -MIN_SWIPE_DISTANCE;

    if (isOpened && isPortraitOrientation && isDownSwipe) onClosed();
    if (!isOpened && isPortraitOrientation && isUpSwipe) onOpened();

    if (isOpened && !isPortraitOrientation && isRightSwipe) onClosed();
    if (!isOpened && !isPortraitOrientation && isLeftSwipe) onOpened();
  };

  useEffect(() => {
    if (!innerRef.current || !isOpened) return;
    setSizeSheet(innerRef.current.offsetHeight);
  }, [isOpened, isPortraitOrientation]);

  const { bottom } = useSafeAreaView();
  const portraitMinSizeSheet = isIOS
    ? MIN_SIZE_SHEET + bottom
    : isAndroid
    ? MIN_SIZE_SHEET + ANDROID_PADDING_BOTTOM
    : MIN_SIZE_SHEET;
  const landscapeMinSizeSheet = isIOS ? MIN_SIZE_SHEET + IOS_PADDING_RIGHT : MIN_SIZE_SHEET;

  useEffect(() => {
    isPortraitOrientation ? setMinSizeSheet(portraitMinSizeSheet) : setMinSizeSheet(landscapeMinSizeSheet);
  }, [isPortraitOrientation, portraitMinSizeSheet, landscapeMinSizeSheet]);

  useOutsideClick(sheetRef, onClosed);

  return (
    <div className={clsx(isOpen && isOverlay && styles.overlay)}>
      <div
        className={clsx(styles.sheet, isOpen && styles.sheet_opened)}
        style={
          isPortraitOrientation
            ? { height: isOpen ? sizeSheet : minSizeSheet }
            : { width: isOpen ? WIDTH_SHEET : minSizeSheet }
        }
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        ref={sheetRef}
      >
        <div className={styles.sheet__inner} ref={innerRef}>
          {children}
        </div>
      </div>
    </div>
  );
});
