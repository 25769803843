import type { NavList } from './types';

export function getDefaultNavList(): NavList {
  return {
    visible: [
      {
        id: 1,
        title: 'wellLogs:wellNav.target',
        type: 'button',
        action: 'target',
        value: true,
        widthElement: 1,
      },
      {
        id: 2,
        title: 'wellLogs:wellNav.search',
        type: 'date',
        action: 'search',
        value: null,
        widthElement: 1,
      },
      {
        id: 3,
        title: 'wellLogs:wellNav.showHeader',
        titleActive: 'wellLogs:wellNav.hideHeader',
        type: 'button',
        action: 'header',
        isIconActive: true,
        value: true,
        widthElement: 1,
      },
      {
        id: 4,
        title: 'wellLogs:wellNav.depthTime',
        type: 'switch',
        action: 'depthTime',
        value: false,
        widthElement: 2,
      },
    ],
    hidden: [
      {
        id: 5,
        title: 'wellLogs:wellNav.barrelFlight',
        type: 'button',
        action: 'barrelFlight',
        value: false,
        widthElement: 1,
      },
      {
        id: 6,
        title: 'wellLogs:wellNav.autoScroll',
        type: 'button',
        action: 'autoScroll',
        value: false,
        widthElement: 1,
      },
    ],
  };
}
