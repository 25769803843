import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ruLocale from 'date-fns/locale/ru';

import { requireService } from 'src/packages/di';

import type { Locale } from '@profgeosoft-ui/react';
import type { Locale as DateFnsLocale } from 'date-fns';

export function getLocale(lang: string): Locale {
  const i18 = requireService('i18');

  return {
    calendar: {
      locale: getDateFnsLocale(lang),
      values: {
        hour: i18.t('common:DatePicker.hour'),
        minuteShort: i18.t('common:DatePicker.minuteShort'),
      },
    },
    input: {
      values: {
        clear: i18.t('common:DatePicker.clear'),
      },
    },
    datePicker: {
      values: {
        apply: i18.t('common:DatePicker.apply'),
        cancel: i18.t('common:Buttons.cancel'),
      },
    },
  };
}

function getDateFnsLocale(lang: string): DateFnsLocale {
  switch (lang) {
    case 'ru':
      return ruLocale;
    case 'es':
      return esLocale;
    case 'en':
    default:
      return enLocale;
  }
}
