import type { TSliderItem } from 'src/packages/ui-kit/switcher/Switcher';

export enum LANGUAGES {
  ru = 'ru',
  en = 'en',
  es = 'es',
}

export const languageSwitcherItems: Record<string, TSliderItem> = {
  ru: { text: 'Русский' },
  en: { text: 'English' },
  es: { text: 'Español' },
};

export const PRIMARY_LANG = window._env_.REACT_APP_PRIMARY_LANGUAGE || process.env.REACT_APP_PRIMARY_LANGUAGE || 'ru';
export const SECONDARY_LANG = window._env_.REACT_APP_SECONDARY_LANGUAGE || process.env.REACT_APP_SECONDARY_LANGUAGE;
