import { Capacitor } from '@capacitor/core';
import { DatePicker } from '@capacitor-community/date-picker';
import { Button, Switch } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRef, type ChangeEvent, useEffect, useId, useState } from 'react';

import { useService } from 'src/packages/di';
import { checkIsNode } from 'src/packages/shared/utils/checkIsNode';

import type { NavItemAction, TNavItem } from '../../types';
import type { WellPageNavStore } from '../../WellPageNav.store';
import type { WellIndexType } from '@go-widgets/well-logs-widget';

import { getButtonIcon, getISOStringDateFromNumber, getSwitchIcons } from './utils';

import styles from './ShortNavItem.module.scss';

type ShortNavItemProps = {
  navItem: TNavItem;
  store: WellPageNavStore;
  onOpenedWellboreRun: VoidFunction;
  onChangeWellType(value: boolean): void;
  wellIndexType: WellIndexType;
  orientation?: OrientationType;
};

export const ShortNavItem = observer(function ShortNavItem({
  navItem,
  store,
  onOpenedWellboreRun,
  onChangeWellType,
  wellIndexType,
  orientation,
}: ShortNavItemProps) {
  const { type, value, action, isIconActive } = navItem;
  const { onNavButtonClick, onNavSwitchClick, onNavDateChange } = store;

  const switchOrientation = orientation && orientation === 'landscape-primary' ? 'vertical' : 'horizontal';
  const timeRange = store.wellLogsAdapter.getTimeRange();
  const isSearchDisabled = type === 'date' && (wellIndexType === 'LOG_DEPTH' || !timeRange);

  const { left, right } = getSwitchIcons();

  const inputDateRef = useRef<HTMLInputElement>(null);
  const dateId = useId();

  const language = useService('language');
  const theme = useService('theme');

  const [searchActive, setSearchActive] = useState(false);

  const handleButton = (action: NavItemAction): void => {
    if (action === 'autoScroll' || action === 'header' || action === 'target') {
      onNavButtonClick(action);
    }
    if (action === 'barrelFlight') {
      onOpenedWellboreRun();
    }
  };

  const onHandleSearch = (event: ChangeEvent<HTMLInputElement>, action: NavItemAction): void => {
    const dateTime = event.target.value ? new Date(event.target.value).getTime() : new Date().getTime();
    onNavDateChange(action, dateTime);
  };

  const onHandleNativeSearch = (str: string, action: NavItemAction): void => {
    const dateTime = new Date(str).getTime() + new Date().getTimezoneOffset() * 60000;
    onNavDateChange(action, dateTime);
  };

  const onHandleDatePicker = () => {
    if (isSearchDisabled || !timeRange) return;

    setSearchActive(true);

    const date =
      (store.wellLogsAdapter.getCenterDate() ?? new Date().valueOf()) - new Date().getTimezoneOffset() * 60000;

    DatePicker.present({
      mode: 'dateAndTime',
      locale: language.language,
      date: new Date(date).toISOString(),
      // eslint-disable-next-line quotes
      format: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      theme: theme.theme,
      min: timeRange.onDate ? new Date(timeRange.onDate).toISOString() : undefined,
      max: timeRange.toDate ? new Date(timeRange.toDate).toISOString() : undefined,
      is24h: true,
    })
      .then((date) => {
        if (date.value) {
          onHandleNativeSearch(date.value, action);
        }
      })
      .catch(() => {})
      .finally(() => {
        setSearchActive(false);
      });
  };

  const changeSwitcher = (action: NavItemAction, value: boolean): void => {
    if (action === 'depthTime') {
      onChangeWellType(value);
      onNavSwitchClick(action, value);
    }
  };

  const setDateMinMax = () => {
    const timeRange = store.wellLogsAdapter.getTimeRange();

    if (!inputDateRef.current) return;

    inputDateRef.current.min = timeRange?.onDate ? getISOStringDateFromNumber(timeRange.onDate) : '';
    inputDateRef.current.max = timeRange?.toDate ? getISOStringDateFromNumber(timeRange.toDate) : '';
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!inputDateRef.current) return;

      if (!checkIsNode(event.target)) return;
      if (inputDateRef.current!.contains(event.target)) return;
      inputDateRef.current.blur();
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.body.removeEventListener('pointerdown', handleClickOutside);
    };
  }, []);

  if (type === 'button') {
    return (
      <Button className={styles.checkButton} onClick={() => handleButton(action)}>
        <div className={clsx(styles.innerButton, value && styles.innerButtonActive)}>
          {getButtonIcon(action, isIconActive, value)}
        </div>
      </Button>
    );
  }

  if (type === 'date') {
    if (Capacitor.getPlatform() !== 'web') {
      return (
        <div
          className={clsx(styles.search, isSearchDisabled && styles.search_disabled, searchActive && styles.Active)}
          onClick={onHandleDatePicker}
        >
          <div className={styles.dateButton}>{getButtonIcon(action)}</div>
        </div>
      );
    }
    return (
      <label className={clsx(styles.search, isSearchDisabled && styles.search_disabled)} htmlFor={dateId}>
        <div className={styles.dateButton}>{getButtonIcon(action)}</div>
        <input
          type="datetime-local"
          className={styles.searchInput}
          disabled={isSearchDisabled}
          onChange={(event) => onHandleSearch(event, action)}
          onClick={setDateMinMax}
          ref={inputDateRef}
          id={dateId}
        />
      </label>
    );
  }

  if (type === 'switch') {
    return (
      <div className={styles.switcher}>
        <Switch
          orientation={switchOrientation}
          checked={value}
          containerClassName={styles.scaleSwitcherContainer}
          className={styles.scaleSwitcher}
          knobClassName={styles.scaleSwitcherKnob}
          leftNode={left}
          rightNode={right}
          onChange={(value) => changeSwitcher(action, value)}
        />
      </div>
    );
  }

  return null;
});
