import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { Router } from 'src/app/router/Router';
import 'src/packages/assets/styles/index.scss';
import { DomainForm } from 'src/components/domain-form/DomainForm';
import { ReactComponent as LogoEPerform } from 'src/packages/assets/img/logo-eperform.svg';
import { requireService } from 'src/packages/di';
import { assert } from 'src/packages/shared/utils/assert';

import type { Auth } from './auth/AuthStore';
import type { FC } from 'react';
import type { KeycloakConfigResponse } from 'src/api/globals';

import { DOMAIN_API_URL_KEY } from './auth/consts';
import { RootStore, RootStoreProvider } from './root-store';
import { initAppServicesAfterAuth, initAppServicesBeforeAuth } from './root-store/initAppServices';

import styles from './App.module.scss';

export const App: FC = observer(function App() {
  const domainApiUrl = localStorage.getItem(DOMAIN_API_URL_KEY);

  const [store, setStore] = useState<RootStore | null>(null);
  const [userInfo, setUserInfo] = useState<Auth.UserInfo | null>(null);
  const [isErrorConfig, setIsErrorConfig] = useState<boolean>(false);

  const init = useCallback(async (domain: string): Promise<void> => {
    let keycloakConfigResponse: KeycloakConfigResponse | null = null;

    try {
      const response = await fetch(`${domain}/mobile-settings.json`).then((res) => res.json());
      keycloakConfigResponse = response;

      setIsErrorConfig(false);
    } catch (e) {
      setIsErrorConfig(true);
      return;
    }

    assert(keycloakConfigResponse);

    await initAppServicesBeforeAuth(domain, keycloakConfigResponse);

    const userService = requireService('userService');

    const userInfo = await userService.auth();

    if (!userInfo) {
      return;
    }

    setUserInfo(userInfo);

    await initAppServicesAfterAuth(userInfo);

    const preloadService = requireService('preloadService');

    await preloadService.preload();

    setStore(new RootStore());
  }, []);

  useEffect(() => {
    if (!domainApiUrl || userInfo) return;

    const domainParsed = JSON.parse(domainApiUrl);

    init(domainParsed);
  }, [init, domainApiUrl, userInfo]);

  if (isErrorConfig || !domainApiUrl || !userInfo) {
    return (
      <div className={styles.loaderContainer}>
        <LogoEPerform className={styles.logoImg} />
        <DomainForm userInfo={userInfo} onDomainSubmit={init} isErrorConfig={isErrorConfig} />
      </div>
    );
  }

  if (!store) {
    return (
      <div className={styles.loaderContainer}>
        <Loader size="l" />
      </div>
    );
  }

  return (
    <RootStoreProvider store={store}>
      <Router />
    </RootStoreProvider>
  );
});
