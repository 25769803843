import { SafeArea } from 'capacitor-plugin-safe-area';
import { useEffect, useState } from 'react';

type TSidebarPadding = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export const useSafeAreaView = () => {
  const [paddings, setPaddings] = useState<TSidebarPadding>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useEffect(() => {
    const setSafeArea = () => {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setPaddings(insets);
      });
    };

    setSafeArea();

    window.addEventListener('resize', setSafeArea);

    return () => {
      window.removeEventListener('resize', setSafeArea);
    };
  }, []);

  return paddings;
};
