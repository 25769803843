import { TemplatesManager, UserSettingsManager } from '@go-widgets/well-logs-widget';
import { makeObservable, observable, runInAction } from 'mobx';

import { requireService } from 'src/packages/di';

import type { WellIndexType, DashboardService, TemplatesManagerEventHandlers } from '@go-widgets/well-logs-widget';

export type TWellLogsUpdateTemplatesNotification = {
  type: 'well-logs-update-templates';
  data: {
    indexType: WellIndexType;
  };
};

export class WellLogsTemplatesManager {
  readonly type: WellIndexType;
  readonly templatesManager: TemplatesManager;
  readonly userSettings: UserSettingsManager;
  readonly api: DashboardService;

  @observable isLoading: boolean = false;

  constructor(
    type: WellIndexType,
    api: DashboardService,
    templatesManagerEventHandlers: TemplatesManagerEventHandlers,
    private readonly authService = requireService('authStore'),
    private readonly notifications = requireService('notifications')
  ) {
    this.api = api;
    this.type = type;
    this.userSettings = new UserSettingsManager(this.api);
    this.templatesManager = new TemplatesManager(this.userSettings, this.api, this.type, templatesManagerEventHandlers);

    makeObservable(this);
  }

  init = async () => {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const ownerUserId = this.authService.userInfo.sub;

      await this.userSettings.loadSettings(ownerUserId, 'CURVE', this.type);
      await this.templatesManager.reloadList();
    } catch (error) {
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadTemplateList');
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}
