import { ReactComponent as MoonIcon } from 'src/packages/assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'src/packages/assets/icons/sun.svg';

import type { TSwitcherItems } from 'src/packages/ui-kit/switcher/Switcher';

export const themeItems: TSwitcherItems = [
  {
    text: 'common:Theme.dark',
    renderCompactIcon: (className) => <MoonIcon className={className} />,
  },
  {
    text: 'common:Theme.light',
    renderCompactIcon: (className) => <SunIcon className={className} />,
  },
];
