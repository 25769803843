import { throwApiError } from 'src/api/utils';
import { requireService, requireServiceAccessor } from 'src/packages/di';

import type { NavList } from '../features/well-page-nav/types';
import type { WidgetStateParams } from '@go-widgets/well-logs-widget';

type TUserMobilePreferencesResponse = {
  id: number;
  status: string;
  objectType: string;
  data: TUserMobilePreferences;
  createdAt: number;
}[];

type TCreateUserMobilePreferencesResponse = {
  id: number;
  objectType: string;
};

export type TUserMobilePreferences = {
  navList?: NavList;
  stateParams?: Partial<WidgetStateParams>;
};

export class UserMobilePreferencesApi {
  constructor(
    private readonly auth = requireServiceAccessor('authStore'),
    private readonly apiService = requireService('apiService')
  ) {}

  async getUserPreferences(): Promise<TUserMobilePreferencesResponse> {
    try {
      const response = await this.apiService.goStorageAgent.get<TUserMobilePreferencesResponse>(
        'object/list/GOdashboard_UserMobilePreferences',
        {
          params: {
            ownerUserId: this.auth().userInfo.sub,
          },
        }
      );

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async createUserPreferences(): Promise<TCreateUserMobilePreferencesResponse> {
    try {
      const response = await this.apiService.goStorageAgent.post<TCreateUserMobilePreferencesResponse>(
        'object/new/GOdashboard_UserMobilePreferences',
        {
          ownerUserId: this.auth().userInfo.sub,
        }
      );

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async updateUserPreferences(
    userPreferencesId: number,
    data: TUserMobilePreferences
  ): Promise<TUserMobilePreferences> {
    try {
      const response = await this.apiService.goStorageAgent.patch<TUserMobilePreferences>(
        `object/GOdashboard_UserMobilePreferences/${userPreferencesId}`,
        {
          ownerUserId: this.auth().userInfo.sub,
          ...data,
        }
      );

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }
}
