import { action, flow, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';
import { RequestBuilder } from 'src/packages/request-builder/RequestBuilder';
import { debounce } from 'src/packages/shared/utils/debounce';
import { mapWells } from 'src/serializers/wells/mapWells';

import type { WellCollapseItemEntity } from './features/well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { TWellListView } from 'src/api-types/wells.types';
import type { WellStatusMessage } from 'src/packages/well-status-service/SockClientService';

import { WellApi } from './features/well-list-widget/api/WellList.api';
import { WellListWidgetEntity } from './features/well-list-widget/WellListWidget.entity';

export class WellListWidgetStore {
  private readonly api: WellApi;

  @observable isInitiated: boolean = false;
  @observable isLoading: boolean = false;
  @observable hasError: boolean = false;
  @observable activeKey: string[] = [];

  wellListEntity: WellListWidgetEntity;
  valueAt: (key: number) => HTMLDivElement | null;
  clearRefs: () => void;

  constructor(
    valueAt: (key: number) => HTMLDivElement | null,
    clearRefs: () => void,
    private readonly notifications = requireService('notifications'),
    readonly favoritesWells = requireService('favoritesWells'),
    private readonly preloadService = requireService('preloadService'),
    private readonly wellStatusService = requireService('wellStatusService'),
    private readonly apiService = requireService('apiService')
  ) {
    this.wellListEntity = new WellListWidgetEntity({
      groupBy: null,
      filterBy: 2,
      searchValue: null,
      collapsedKeys: null,
    });
    this.api = new WellApi(apiService);

    this.valueAt = valueAt;
    this.clearRefs = clearRefs;

    makeObservable(this);
  }

  @action.bound
  onCollapseKeysChange(key: string[]): void {
    let collapsedKeys: string[] = [];

    for (const itemKey of key) {
      if (this.wellListEntity.collapsedKeys.has(itemKey)) {
        this.wellListEntity.removeCollapsedKey(itemKey);
      }
    }

    for (const itemKey of this.activeKey) {
      if (!key.includes(itemKey)) {
        collapsedKeys.push(itemKey);
      }
    }

    for (const deletedKey of collapsedKeys) {
      this.wellListEntity.addCollapsedKey(deletedKey);
    }

    this.activeKey = key;
  }

  @action.bound
  changeFilterBy(filter: number): void {
    this.wellListEntity.changeFilterBy(filter);
    this.fetchWells();
  }

  @action.bound
  changeGroupBy(groupBy: number): void {
    this.wellListEntity.changeGroupBy(groupBy);
    this.fetchWells();
  }

  @action.bound
  changeSearchValue(value: string): void {
    this.wellListEntity.changeSearchValue(value);

    this.debouncedFetchWells();
  }

  @action.bound
  toggleFavorite(well: WellCollapseItemEntity): void {
    const desiredWellId = this.favoritesWells.wellIds.includes(well.id);

    if (desiredWellId) {
      this.favoritesWells.disableFavorite(well);
    } else {
      this.favoritesWells.enableFavorite(well);
    }
  }

  @action.bound
  setInitiated(value: boolean): void {
    this.isInitiated = value;
  }

  @action.bound
  clearSearch(): void {
    this.wellListEntity.changeSearchValue('');

    this.debouncedFetchWells();
  }

  @action.bound
  debouncedFetchWells = debounce(() => {
    this.fetchWells();
  }, 500);

  @flow.bound
  async *fetchWells() {
    const view = this.preloadService.getPreloadedData<TWellListView>('well-list-control');

    this.clearRefs();

    try {
      this.hasError = false;
      this.isLoading = true;

      const builder = new RequestBuilder().configure((settings) => {
        settings
          .useDataSource(this.favoritesWells.dataSource, { prefix: '$' })
          .useDataSource(this.wellListEntity.createDataSource(), { prefix: '$' });
      });

      const currentGrouping = view.grouping.groups.find((_, index) => index === this.wellListEntity.groupBy);
      const currentFilter = view.filtering.filters.find((_, index) => index === this.wellListEntity.filterBy);

      if (!currentGrouping || !currentFilter) return;

      for (const request of currentGrouping.request) {
        builder.apply(request);
      }

      for (const request of currentFilter.request) {
        builder.apply(request);
      }

      for (const request of view.search.request) {
        builder.apply(request);
      }

      for (const request of view.outerFilterRequests) {
        builder.apply(request);
      }

      const { postData, getParams } = builder.build();

      if (typeof postData !== 'object' || !postData) return;

      const wells = await this.api.getWells(postData, getParams);

      yield;

      const _wells = wells ? mapWells(wells, view, this.wellListEntity.groupBy) : [];

      this.wellListEntity.setWells(_wells);

      this.activeKey = _wells.map((well) => well.id).filter((key) => !this.wellListEntity.collapsedKeys.has(key));
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToLoadWells');
    } finally {
      this.isLoading = false;
    }
  }

  private onWellStatusChange = (wellStatusMessage: WellStatusMessage): void => {
    if (wellStatusMessage.status === 'COMPLETED') {
      this.debouncedFetchWells();
    } else {
      this.wellListEntity.updateWellStatus(wellStatusMessage.wellId, wellStatusMessage.status);
    }
  };

  effect = () => {
    this.favoritesWells.loadFavoritesIds();
    this.wellStatusService.subscribe('/wellStatus', this.onWellStatusChange);

    this.setInitiated(true);

    return () => {
      this.wellStatusService.unsubscribe('wellStatus', this.onWellStatusChange);
    };
  };
}
