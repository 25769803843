import { useSortable } from '@dnd-kit/sortable';
import { MoveCustom } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type {
  SourcesOverload,
  TrackOverload,
} from '@go-widgets/well-logs-widget/dist/stores/WidgetTracksSettings/TrackOverload';

import styles from './Curve.module.scss';

type CurveProps = {
  curve: SourcesOverload;
  track?: TrackOverload;
};

export const Curve = observer(function Curve({ curve, track }: CurveProps) {
  const { t } = useTranslation('settings');
  const { transform, attributes, isDragging, listeners, setNodeRef, transition } = useSortable({
    id: curve.id,
    data: {
      item: curve,
    },
  });

  const handleChange = () => {
    curve.changeVisibility(!curve.isVisible);
    if (track) track.checkCurvesVisibility();
  };

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    opacity: isDragging ? 0.5 : 1,
    transition,
  };

  return (
    <label
      ref={setNodeRef}
      style={style}
      className={clsx(styles.container, curve.isVisible && styles.container_active)}
      {...listeners}
      {...attributes}
      htmlFor={curve.id}
    >
      <div className={styles.dragIconWrapper}>
        <MoveCustom width={13} height={13} />
      </div>
      <input
        className={styles.checkbox}
        onChange={handleChange}
        id={curve.id}
        type="checkbox"
        checked={curve.isVisible}
      />
      <p className={styles.labelText}>
        {curve.mnemonic}
        {curve.noData && (
          <span className={clsx(styles.noDataSource, !curve.isVisible && styles.noDataSource_notActive)}>
            {t('configTab.noData')}
          </span>
        )}
        {curve.noSource && (
          <span className={clsx(styles.noDataSource, !curve.isVisible && styles.noDataSource_notActive)}>
            {t('configTab.noSource')}
          </span>
        )}
      </p>
    </label>
  );
});
