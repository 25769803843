import { action, makeObservable, observable } from 'mobx';

import type { WellLogsWidgetStore } from '../../../well-logs-widget/WellLogsWidget.store';
import type { WellLogWidgetObservableAdapter, Wellbore } from '@go-widgets/well-logs-widget';

export type Tabs = {
  value: number;
  label: string;
  disabled?: boolean;
};
export class WellboreRunStore {
  private readonly adapter: WellLogWidgetObservableAdapter;

  @observable wellboresList: Wellbore[] = [];
  @observable runNumbersList: number[] = [];
  @observable selectedWellbore: number = 0;
  @observable selectedRunNumber: number = 0;

  constructor(store: WellLogsWidgetStore) {
    this.adapter = store.wellLogsAdapter;
    this.wellboresList = store.wellLogsAdapter.wellboresList;
    this.runNumbersList = store.wellLogsAdapter.runNumbersList;
    this.selectedWellbore = store.wellLogsAdapter.selectedWellbore?.id ?? 0;
    this.selectedRunNumber = store.wellLogsAdapter.selectedRunNumber ?? 0;

    makeObservable(this);
  }

  @action.bound
  setWellbore(bore: Wellbore): void {
    this.adapter.setWellbore(bore);
    this.selectedWellbore = bore.id;
  }

  @action.bound
  setRunNumber(run: number): void {
    this.adapter.setRunNumber(run);
    this.selectedRunNumber = run;
  }
}
