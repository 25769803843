import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { LanguageSwitcher } from 'src/components/sidebar/language-switcher/LanguageSwitcher';

import { ThemeSwitcher } from '../theme-switcher/ThemeSwitcher';

import styles from './Switchers.module.scss';

export const Switchers = observer(function Switchers() {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.switcherWrapper)}>
        <LanguageSwitcher orientation="horizontal" isCompact={false} />
      </div>
      <div className={clsx(styles.switcherWrapper)}>
        <ThemeSwitcher orientation="horizontal" isCompact={false} />
      </div>
    </div>
  );
});
