import { action, makeObservable, observable, computed } from 'mobx';

import type { IScreenOrientationService } from './IScreenOrientationService';

import { getOrientation } from './utils';

export class ScreenOrientationService implements IScreenOrientationService {
  @observable orientation: OrientationType;

  constructor() {
    this.orientation = getOrientation();

    makeObservable(this);
  }

  @action.bound
  setScreenOrientation(value: OrientationType): void {
    this.orientation = value;
  }

  @computed
  get isPortraitOrientation(): boolean {
    return this.orientation === 'portrait-primary';
  }

  @action.bound
  private updateOrientation = () => {
    this.orientation = getOrientation();
  };

  init = () => {
    window.addEventListener('resize', this.updateOrientation);
  };
}
