import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MoveCustom as MoveIcon } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TNavItem } from '../../types';
import type { WellPageNavStore } from '../../WellPageNav.store';
import type { UniqueIdentifier } from '@dnd-kit/core';
import type { WellIndexType } from '@go-widgets/well-logs-widget';

import { NavItem } from '../nav-item/NavItem';

import styles from './SortableItem.module.scss';

type SortableItemProps = {
  id: UniqueIdentifier;
  navItem: TNavItem;
  store: WellPageNavStore;
  onOpenedWellboreRun: VoidFunction;
  onChangeWellType(value: boolean): void;
  wellIndexType: WellIndexType;
};

export const SortableItem = observer(function SortableItem({
  id,
  navItem,
  store,
  onOpenedWellboreRun,
  onChangeWellType,
  wellIndexType,
}: SortableItemProps) {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { title, titleActive, value, action } = navItem;

  const isDisabled = action === 'search' && wellIndexType === 'LOG_DEPTH';

  return (
    <div ref={setNodeRef} style={style} className={styles.sortableItem} {...attributes} {...listeners}>
      <MoveIcon className={styles.moveIcon} />

      <NavItem
        navItem={navItem}
        store={store}
        onOpenedWellboreRun={onOpenedWellboreRun}
        onChangeWellType={onChangeWellType}
        wellIndexType={wellIndexType}
      />

      <p className={clsx(styles.title, isDisabled && styles.title_disabled)}>
        {value && titleActive ? t(titleActive) : t(title)}
      </p>
    </div>
  );
});
