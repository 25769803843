import { action, computed, flow, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { TUserMobilePreferences } from './api/UserMobilePreferences.api';
import type { NavList } from './features/well-page-nav/types';
import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';

import { UserMobilePreferencesApi } from './api/UserMobilePreferences.api';
import { getDefaultNavList } from './features/well-page-nav/utils';

export class WellPageStore {
  private readonly api = new UserMobilePreferencesApi();

  @observable isLoading: boolean = false;
  @observable userPreferencesId?: number;
  @observable navList: NavList | null = null;
  @observable stateParams: Partial<WidgetStateParams> = {};

  constructor(private readonly notifications = requireService('notifications')) {
    makeObservable(this);
  }

  @computed
  get wellIndexType(): WellIndexType | null {
    if (!this.navList) return null;

    const depthTime = [...this.navList.visible, ...this.navList.hidden].find((item) => item.action === 'depthTime');

    return depthTime?.value ? 'LOG_DEPTH' : 'LOG_TIME';
  }

  init = async () => {
    this.loadUserPreferences();
  };

  @flow.bound
  async *loadUserPreferences() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;
      const preferencesResponse = await this.api.getUserPreferences();

      yield;

      if (preferencesResponse.length === 0) {
        const newPreferencesObject = await this.api.createUserPreferences();

        yield;

        this.userPreferencesId = newPreferencesObject.id;
        this.navList = getDefaultNavList();
      } else {
        const {
          data: { navList, stateParams },
          id,
        } = preferencesResponse[0];

        this.userPreferencesId = id;
        this.navList = navList ? navList : getDefaultNavList();

        if (stateParams) {
          this.stateParams = stateParams;
        }
      }
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadAppSettings');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *updatePreferences(preferences: TUserMobilePreferences) {
    if (!this.userPreferencesId) return;

    try {
      if (preferences.navList) {
        this.navList = preferences.navList;
      }

      await this.api.updateUserPreferences(this.userPreferencesId, preferences);

      yield;
    } catch (error) {
      yield;

      console.error(error);
    }
  }

  @action.bound
  onChangeWellType(value: boolean): void {
    if (!this.navList) return;

    const wellIndexSwitcher = [...this.navList.visible, ...this.navList.hidden].find(
      (item) => item.action === 'depthTime'
    );

    if (wellIndexSwitcher) {
      wellIndexSwitcher.value = value;
    }
  }
}
