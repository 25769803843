import { createNotification } from '@profgeosoft-ui/react';

import { requireServiceAccessor } from 'src/packages/di';

import type { TOptions } from 'i18next';
import type { TFuncKey } from 'react-i18next';

export interface INotificationsService {
  showErrorMessage(text: string): void;
  showErrorMessageT(text: TFuncKey, options?: TOptions): void;
  showWarningMessage(text: string): void;
  showWarningMessageT(text: TFuncKey, options?: TOptions): void;
  showSuccessMessage(text: string): void;
  showSuccessMessageT(text: TFuncKey, options?: TOptions): void;
}

export class NotificationsStore {
  constructor(private readonly getI18 = requireServiceAccessor('i18')) {}

  showErrorMessage(text: string): void {
    createNotification({
      type: 'error',
      text,
      position: 'bottom-right',
    });
  }

  showErrorMessageT(text: TFuncKey, options?: TOptions): void {
    const messageText = this.getI18().t(text, options);

    this.showErrorMessage(messageText);
  }

  showWarningMessage(text: string): void {
    createNotification({
      type: 'info',
      text,
      position: 'bottom-right',
    });
  }

  showWarningMessageT(text: TFuncKey, options?: TOptions): void {
    const messageText = this.getI18().t(text, options);

    this.showWarningMessage(messageText);
  }

  showSuccessMessage(text: string): void {
    createNotification({
      type: 'success',
      text,
      position: 'bottom-right',
    });
  }

  showSuccessMessageT(text: TFuncKey, options?: TOptions): void {
    const messageText = this.getI18().t(text, options);

    this.showSuccessMessage(messageText);
  }
}
