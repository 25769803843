import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { MainLayout } from 'src/layouts/main-layout/MainLayout';

import type { FC } from 'react';

export const AnalyticsPage: FC = observer(function AnalyticsPage() {
  const isLoading = false;

  const renderContent = () => {
    if (isLoading) {
      return <Loader size="l" />;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 56px)' }}>
        Страница в разработке
      </div>
    );
  };

  return <MainLayout>{renderContent()}</MainLayout>;
});
