import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { useStore } from 'src/app/root-store';
import { Switchers } from 'src/components/sidebar/switchers/Switchers';
import { checkIsNode } from 'src/packages/shared/utils/checkIsNode';

import { Logo } from './logo/Logo';
import { Navigation } from './navigation/Navigation';
import { UserSection } from './user-section/UserSection';

import styles from './Sidebar.module.scss';

export const Sidebar = observer(function Sidebar() {
  const { sidebar, auth } = useStore();
  const isOpened = sidebar.isOpened;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (ref.current!.contains(event.target)) return;
      sidebar.close();
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.body.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [sidebar]);

  return (
    <>
      <div ref={ref} className={clsx(styles.wrapper, !isOpened && styles.wrapper__closed)}>
        <div>
          <Logo />
          <Navigation isOpened={isOpened} onCloseSidebar={sidebar.close} />
        </div>
        <div>
          <Switchers />
          <UserSection isOpened={isOpened} userInfo={auth.userInfo} onLogout={auth.logout} />
        </div>
        <button className={clsx(styles.closeButton, isOpened && styles.closeButton__opened)} onClick={sidebar.close} />
      </div>
    </>
  );
});
