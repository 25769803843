import type { ApiService } from 'src/api/ApiService';

export class WellApi {
  constructor(private readonly apiService: ApiService) {}
  async getWells(body: unknown, params: Partial<Record<string, string | number>>): Promise<object> {
    const wells = await this.apiService.agent.post<object>('wells/select', body, {
      params,
    });

    return wells.data;
  }
}
