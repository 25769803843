import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  WidgetSettings,
  type WellLogWidgetObservableAdapter,
  type WellLogsWidgetState,
} from '@go-widgets/well-logs-widget';
import { BoxRefreshAltRightLight } from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { TracksOverloadState } from '@go-widgets/well-logs-widget/dist/stores/WidgetTracksSettings/TrackOverload';

import { Track } from '../track/Track';

import styles from './ViewSettings.module.scss';

type ViewSettingsProps = {
  adapter: WellLogWidgetObservableAdapter;
  wellLogsWidget: WellLogsWidgetState;
  tracksOverloadState: TracksOverloadState;
};

export const ViewSettings = observer(function ViewSettings({
  adapter,
  wellLogsWidget,
  tracksOverloadState,
}: ViewSettingsProps) {
  const { isPortraitOrientation } = useService('screen');
  const { t } = useTranslation('settings');

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [{ onDragStart, onDragEnd, onDragCancel, onDragOver }] = useState(
    () => new WidgetSettings(tracksOverloadState, wellLogsWidget.localizationStore)
  );

  const { tracks } = tracksOverloadState;

  const onReset = () => {
    adapter.resetTracksOverloadState(tracksOverloadState);
  };

  return (
    <>
      <Button variant="flat" className={styles.resetButton} icon={<BoxRefreshAltRightLight />} onClick={onReset}>
        {t('configTab.resetButton')}
      </Button>
      <div className={styles.overflowContainer}>
        <div className={clsx(styles.wrapper, !isPortraitOrientation && styles.horizontal)}>
          {tracks && (
            <DndContext
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragCancel={onDragCancel}
              onDragOver={onDragOver}
              collisionDetection={pointerWithin}
              sensors={sensors}
            >
              <SortableContext
                strategy={isPortraitOrientation ? verticalListSortingStrategy : horizontalListSortingStrategy}
                items={tracks}
              >
                {tracks.map((track) => (
                  <Track key={track.id} track={track} />
                ))}
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
    </>
  );
});
