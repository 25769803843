import i18n from 'i18next';

export function getSettingsTabs() {
  return [
    { value: 1, label: i18n.t('settings:tabs.common') },
    { value: 2, label: i18n.t('settings:tabs.config') },
    { value: 3, label: i18n.t('settings:tabs.templates') },
  ];
}

export function headerVariants() {
  return [
    {
      label: i18n.t('settings:commonTab.headerVariantOff'),
      value: 'off',
    },
    {
      label: i18n.t('settings:commonTab.headerVariantOn'),
      value: 'on',
    },
  ];
}
