import { requireService, type IServicesCollection } from 'src/packages/di';
import { StompPublisherService } from 'src/pages/wells/features/workspace/api/StompPublisherService';
import { PreloadWidgetService } from 'src/pages/wells/features/workspace/services/PreloadWidgetService';

import type { IThemeService } from '../theme/ThemeStore';
import type { IStompPublisherService } from 'src/pages/wells/features/workspace/api/StompPublisherService';

import { ThemeStore } from '../theme/ThemeStore';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    preloadService: PreloadWidgetService;
    stompPublisherService: IStompPublisherService;
    theme: IThemeService;
  }
}

export async function addCommonServices(di: IServicesCollection) {
  const globals = requireService('globals');
  const stompPublisherService = new StompPublisherService(globals);

  stompPublisherService.init();

  di.addSingleton('preloadService', new PreloadWidgetService())
    .addSingleton('stompPublisherService', stompPublisherService)
    .addSingleton('theme', new ThemeStore());
}
