import { action, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { WellIndexType, WellLogsWidgetState, WidgetStateParams } from '@go-widgets/well-logs-widget';

interface WellLogsWidgetOptions {
  stateParams: Partial<WidgetStateParams>;
  wellIndexType: WellIndexType;
  isEditMode: boolean;
  wellId: number | null;
}

export type TWellLogsSerializedInternalState = {
  stateParams: Partial<WidgetStateParams>;
  wellIndexType: WellIndexType;
  isEditMode: boolean;
  wellId: number | null;
};

export class WellLogsWidgetEntity {
  @observable state: WellLogsWidgetState | null = null;
  @observable wellIndexType: WellIndexType;
  @observable isEditMode: boolean;
  @observable wellId: number | null;
  @observable stateParams: Partial<WidgetStateParams>;

  constructor(
    { wellIndexType, stateParams, isEditMode, wellId }: WellLogsWidgetOptions,
    private readonly i18 = requireService('i18')
  ) {
    this.wellIndexType = wellIndexType;
    this.isEditMode = isEditMode;
    this.wellId = wellId;
    this.stateParams = stateParams;

    makeObservable(this);
  }

  getNameT(): string {
    if (this.wellIndexType === 'LOG_DEPTH') {
      return 'settings:Widgets.byDepth';
    }

    return 'settings:Widgets.byTime';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setStateParams(stateParams: Partial<WidgetStateParams>): void {
    this.stateParams = stateParams;
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    return this.i18.t('wellLogs:title');
  }

  @action.bound
  setState(state: WellLogsWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setEditMode(value: boolean): void {
    this.isEditMode = value;
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.state?.setAppParams({
      fullScreen: value,
    });
  }
}
