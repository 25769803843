import { useSortable } from '@dnd-kit/sortable';
import { ValueScaleType } from '@go-widgets/well-logs-widget/dist/api/dto/TrackDto';
import { EyeLight, MoveCustom, ViewHideLight } from '@profgeosoft-ui/icons';
import { Switch } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TrackOverload } from '@go-widgets/well-logs-widget/dist/stores/WidgetTracksSettings/TrackOverload';

import { Curves } from '../curves/Curves';

import styles from './Track.module.scss';

type TrackProps = { track: TrackOverload; orientation?: 'vertical' | 'horizontal' };

export const Track = observer(function Track({ track, orientation }: TrackProps) {
  const { t } = useTranslation('settings');

  const { setNodeRef, listeners, attributes, transform, isDragging, transition } = useSortable({
    id: track.id,
    data: {
      item: track,
    },
  });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    opacity: isDragging ? 0.5 : 1,
    transition,
  };

  if (track.type === ValueScaleType.Activities || track.type === ValueScaleType.Numeric) {
    return (
      <div className={styles.blockedTrack} style={style} ref={setNodeRef} {...listeners} {...attributes}>
        <div className={styles.blockedTrackTitle}>
          {track.type === ValueScaleType.Numeric ? t('configTab.scaleNumeric') : t('configTab.scaleActivities')}
        </div>

        <Curves track={track} curves={track.sources} align="right" />
      </div>
    );
  }

  return (
    <div className={styles.track} style={style} ref={setNodeRef}>
      <div className={styles.header}>
        <div className={styles.wrapper}>
          <div {...listeners} {...attributes} className={styles.dragIconWrapper}>
            <MoveCustom />
          </div>
          <Switch
            checked={track.isVisible}
            onChange={() => {
              track.changeVisibility(!track.isVisible);
              track.setAllCurvesVisibility();
            }}
            size="m"
            rightNode={<EyeLight />}
            leftNode={<ViewHideLight />}
            className={styles.switchTrack}
          />
          <div className={styles.title}>
            {t('configTab.track')} {track.pos + 1}
          </div>
        </div>
      </div>

      <Curves track={track} curves={track.sources} />
    </div>
  );
});
